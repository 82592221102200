import axios from "axios";
import { urlRetiradasExtras } from "../../../../../constants/endpoints";

// retiradaExtra
export const listaRetiradasExtra = (filter = null) => axios.get(urlRetiradasExtras, { params: filter });
export const atualizaStatusRetiradaExtra = (idRetirada, newStatus, idResponsavel, idProdutoFilho = null) => {
  const payloadToUpdate = {
    status: newStatus,
    id_responsavel: idResponsavel
  };

  if (idProdutoFilho) {
    payloadToUpdate.id_produto = idProdutoFilho;
  }

  return axios.patch(`${urlRetiradasExtras}/atualizarStatus/${idRetirada}`, payloadToUpdate);
}
