import dayjs from 'dayjs';
import jsPDF from "jspdf";
import "jspdf-autotable";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Logotipo from "../../../../../assets/images/stw_logo_preto.png";
import { maskCNPJ } from '../../../../../configs/functions';
import { Info } from '@mui/icons-material';
import { ClientManagementIcon, GoodPincodeIcon, NameTagIcon } from '../../../../common/Icons';

// default pdf settings
const marginLeft = 40;
const marginSecondColumn = 440;
const unit = "pt";
const size = "A4";
const orientation = "landscape";

const defaultConfigStylesTable = {
  fontSize: 8,
  overflow: 'linebreak'
};

function addLineDivider(doc, marginLeft, yPosition) {
  doc.setLineWidth(1);
  doc.setDrawColor(204, 204, 204);
  doc.line(marginLeft, yPosition, 800, yPosition);
}

function addFooter(doc, data, user) {
  const nome = user?.nome || 'Não informado';
  const matricula = user?.matricula || 'Não informada';

  const maxLengthNameInFooter = 25;
  const displayNomeInFooter = nome.length > maxLengthNameInFooter ? nome.substring(0, maxLengthNameInFooter) + '...' : nome;
  const pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
  let pageNumber = `Página ${data.pageNumber}`;
  let generatedInfo = `Relatório gerado por: ${displayNomeInFooter}, Matrícula: ${matricula} - ${dayjs().format('DD/MM/YYYY HH:mm:ss')}`;

  doc.setFontSize(8);
  doc.setTextColor(100, 100, 100)

  doc.text(pageNumber, data.settings.margin.left, pageHeight - 20);
  doc.text(generatedInfo, data.settings.margin.left + 475, pageHeight - 20);
}

function headerReport(doc, empresa, reportName, borderBottomWidth) {
  doc.setFont('helvetica', 'bold');

  const maxWidth = 100;
  const maxHeight = 55;
  let posYLogo = 20;

  const logoToPrint = empresa?.logo || Logotipo;

  let imgWidth = 100;
  let imgHeight = 50;

  if (logoToPrint === empresa?.logo) {
    const imgProps = doc.getImageProperties(logoToPrint);

    const resizedImage = calculateImageDimensions(imgProps, maxWidth, maxHeight);

    imgWidth = resizedImage.width;
    imgHeight = resizedImage.height;

    if (imgHeight < maxHeight) {
      // ajustar posição vertical da imagem
      const diff = maxHeight - imgHeight;
      posYLogo += diff / 2;
    }
  }

  doc.addImage(logoToPrint, 'PNG', marginLeft, posYLogo, imgWidth, imgHeight);

  doc.setFontSize(13);
  doc.text(reportName, (1.2 * marginLeft) + imgWidth, 40);

  // LINE DIVIDER
  doc.setLineWidth(1);
  doc.setDrawColor(204, 204, 204);
  doc.line((1.2 * marginLeft) + imgWidth, 45, ((1.2 * marginLeft) + imgWidth) + borderBottomWidth, 45);  // Draw line (x1, y1, x2, y2)

  doc.setFont('helvetica', 'normal');
  doc.setFontSize(10);
  doc.text("Sistema de Controle de Entrega de EPIs", (1.2 * marginLeft) + imgWidth, 60);

  if (empresa) {
    doc.setFont('helvetica', 'bold');
    doc.text("Empresa:", marginLeft, 90);
    doc.setFont('helvetica', 'normal');
    doc.text(empresa.nomeCompanhia, marginLeft + 50, 90);

    doc.setFont('helvetica', 'bold');
    doc.text("CNPJ:", marginLeft + 630, 90);
    doc.setFont('helvetica', 'normal');
    doc.text(maskCNPJ(empresa.cnpj), marginLeft + 665, 90);
  }
}

// Função para acessar valores aninhados
const getNestedValue = (obj, field) => {
  const keys = field.split('.');
  let value = obj;
  for (let key of keys) {
    if (value && value[key] !== undefined) {
      value = value[key];
    } else {
      return null; // Se o campo aninhado não existir, retorna null
    }
  }
  return value;
};

function extractHeadersAndFilteredColumns(visibleColumns) {
  if (!Array.isArray(visibleColumns) || !visibleColumns.length) {
    throw new Error('Visible columns are missing or not properly formatted');
  }

  const filteredColumns = visibleColumns.filter(col => col.headerName !== 'Ações');

  const headers = filteredColumns.map(col => col.headerName);

  return { headers, filteredColumns };
}

function getValueFromColumn(col, row) {
  if (col.valueGetter && col.renderCell) {
    return col.renderCell({ row });
  }

  return col.valueGetter ? col.valueGetter({ row }) : getNestedValue(row, col.field) ?? '-';
}

function extractDataToExport(visibleColumns, tableData) {
  const { headers, filteredColumns } = extractHeadersAndFilteredColumns(visibleColumns);
  const formattedData = tableData.map(row => filteredColumns.map(col => getValueFromColumn(col, row)));

  return { headers, formattedData };
}

function extractDataToExportJson(columns, tableData) {
  const dataWithHeaders = tableData.map((row) => {
    const obj = {};
    columns
      .filter((column) => column.headerName)
      .forEach((col) => {
        if (col.headerName) {
          obj[col.headerName] = getValueFromColumn(col, row)
        }
      });
    return obj;
  });

  return { dataWithHeaders };
}

export function exportFichaEPIToPDF(dataToExport) {
  const {
    tableData,
    visibleColumns,
    colaborador,
    selectEmpresa: empresa,
    dadosUsuario,
    reportName,
    termoResponsabilidade,
    termoAceite,
    exibirFotosOption,
  } = dataToExport;

  const { headers, formattedData } = extractDataToExport(visibleColumns, tableData);

  const doc = new jsPDF(orientation, unit, size);
  headerReport(doc, empresa, "COMPROVANTE DE FORNECIMENTO DE EPI", 280);

  // DADOS DO COLABORADOR (FICHA EPI)
  const nomeColaborador = colaborador?.nome || 'Não informado';
  const matriculaColaborador = colaborador?.matricula || 'Não informada';
  const funcaoColaborador = colaborador?.funcao?.nome || 'Não informada';
  const setorColaborador = colaborador?.setor?.nome || 'Não informado';

  doc.setFont('helvetica', 'bold');
  doc.text("Dados do Colaborador:", marginLeft, 120);
  doc.setFont('helvetica', 'normal');

  addLineDivider(doc, marginLeft, 132);

  doc.setFont('helvetica', 'bold');
  doc.text("Nome:", marginLeft, 150);
  doc.setFont('helvetica', 'normal');
  doc.text(nomeColaborador, marginLeft + 35, 150);

  doc.setFont('helvetica', 'bold');
  doc.text("Matrícula:", marginLeft + marginSecondColumn, 150);
  doc.setFont('helvetica', 'normal');
  doc.text(matriculaColaborador, marginLeft + marginSecondColumn + 50, 150);

  doc.setFont('helvetica', 'bold');
  doc.text("Função Atual:", marginLeft, 170);
  doc.setFont('helvetica', 'normal');
  doc.text(funcaoColaborador, marginLeft + 70, 170);

  doc.setFont('helvetica', 'bold');
  doc.text("Setor:", marginLeft + marginSecondColumn, 170);
  doc.setFont('helvetica', 'normal');
  doc.text(setorColaborador, marginLeft + marginSecondColumn + 33, 170);

  addLineDivider(doc, marginLeft, 182);

  doc.setTextColor(100, 100, 100);

  const termoResponsabilidadeText = termoResponsabilidade || "";
  const termoAceiteText = termoAceite || "";

  doc.text(termoResponsabilidadeText, marginLeft, 205, { maxWidth: 750 });

  // splitTextToSize -> retorna um array de strings, onde cada string é uma linha do texto
  const linhasResponsabilidade = doc.splitTextToSize(termoResponsabilidadeText, 750, { fontSize: 10 });
  const numberOfLines = linhasResponsabilidade.length;

  const tableStartY = 210 + (numberOfLines * 12);

  const tiposAutenticacao = tableData.map(item => item.tipo_autenticacao);
  const biometricPhotos = exibirFotosOption ? tableData.map(row => row.foto && row.foto) : [];

  const tableContent = {
    head: [headers],
    body: formattedData,
    startY: tableStartY,
    theme: 'grid',
    rowPageBreak: 'avoid',
    fillColor: [255, 0, 0],
    headStyles: { fillColor: [30, 81, 57] },
    styles: defaultConfigStylesTable,
    bodyStyles: { minCellHeight: exibirFotosOption ? 80 : 20 },
    didDrawCell: (data) => renderAuthenticationImage(doc, data, headers.indexOf('Autenticação'), tiposAutenticacao, biometricPhotos),
    didDrawPage: data => addFooter(doc, data, dadosUsuario.pessoa)
  };

  doc.autoTable(tableContent);

  const pageHeight = doc.internal.pageSize.height;

  const startYAcceptTerm = doc.autoTable.previous.finalY + 25;
  const linhasAceite = doc.splitTextToSize(termoAceiteText, 750);
  const termoAceiteHeight = linhasAceite.length * 10;

  if (startYAcceptTerm + termoAceiteHeight > pageHeight - 30) {
    doc.addPage();
    doc.text(termoAceiteText, marginLeft, 30, { maxWidth: 750 });
  } else {
    doc.text(termoAceiteText, marginLeft, startYAcceptTerm, { maxWidth: 750 });
  }

  doc.save(`${reportName}_${dayjs().format('YYYY-MM-DD_HHmm')}.pdf`);
}

export function exportSaldoEPIPessoaToPDF(dataToExport) {
  const { tableData, visibleColumns, colaborador, selectEmpresa: empresa, dadosUsuario, reportName } = dataToExport;
  const { headers, formattedData } = extractDataToExport(visibleColumns, tableData);

  // const formattedData = tableData.map(item => {
  //   return [
  //     item?.produto.ca || 'Não informado',
  //     item?.produto.nome || 'Não informado',
  //     item?.produto.cod_externo || 'Não informado',
  //     item?.qtd_disponivel || 0,
  //     item?.produto.validade_ca ? dayjs(item.produto.validade_ca).format('DD/MM/YYYY') : 'Não informado',
  //     item?.produto.vida_util || 'Não informado',
  //     item?.produto.categorias?.nome || 'Não informado',
  //     item?.produto.unidade_medida?.nome || 'Não informado',
  //   ];
  // });

  const doc = new jsPDF(orientation, unit, size);

  headerReport(doc, empresa, "COMPROVANTE DE FORNECIMENTO DE EPI", 280);

  // DADOS DO COLABORADOR (FICHA EPI)
  const nomeColaborador = colaborador?.nome || 'Não informado';
  const matriculaColaborador = colaborador?.matricula || 'Não informada';
  const funcaoColaborador = colaborador?.funcao?.nome || 'Não informada';
  const setorColaborador = colaborador?.setor?.nome || 'Não informado';

  doc.setFont('helvetica', 'bold');
  doc.text("Dados do Colaborador:", marginLeft, 120);
  doc.setFont('helvetica', 'normal');

  addLineDivider(doc, marginLeft, 132);

  doc.setFont('helvetica', 'bold');
  doc.text("Nome:", marginLeft, 150);
  doc.setFont('helvetica', 'normal');
  doc.text(nomeColaborador, marginLeft + 35, 150);

  doc.setFont('helvetica', 'bold');
  doc.text("Matrícula:", marginLeft + marginSecondColumn, 150);
  doc.setFont('helvetica', 'normal');
  doc.text(matriculaColaborador, marginLeft + marginSecondColumn + 50, 150);

  doc.setFont('helvetica', 'bold');
  doc.text("Função Atual:", marginLeft, 170);
  doc.setFont('helvetica', 'normal');
  doc.text(funcaoColaborador, marginLeft + 70, 170);

  doc.setFont('helvetica', 'bold');
  doc.text("Setor:", marginLeft + marginSecondColumn, 170);
  doc.setFont('helvetica', 'normal');
  doc.text(setorColaborador, marginLeft + marginSecondColumn + 33, 170);

  addLineDivider(doc, marginLeft, 182);

  const tableContent = {
    head: [headers],
    body: formattedData,
    startY: 200,
    theme: 'grid',
    fillColor: [255, 0, 0],
    headStyles: { fillColor: [30, 81, 57] },
    styles: defaultConfigStylesTable,
    didDrawPage: (data) => addFooter(doc, data, dadosUsuario.pessoa)
  };

  doc.autoTable(tableContent);
  doc.save(`${reportName}_${dayjs().format('YYYY-MM-DD_HHmm')}.pdf`);
}

export function exportFichaEPIEmpresaToPDF(dataToExport) {
  const tableData = dataToExport.tableData;
  const empresa = dataToExport.selectedEmpresa;
  const user = dataToExport.dadosUsuario.pessoa;
  const dataInicio = dataToExport.dataInicio;
  const dataFim = dataToExport.dataFim;

  const doc = new jsPDF(orientation, unit, size);

  const headers = ["Produto", "CA", "Quantidade", "Função", "Responsável", "Data Retirada"];

  headerReport(doc, empresa, "COMPROVANTE DE FORNECIMENTO DE EPI", 280);

  // percorrer cada responsável
  tableData.forEach((colaborador, index) => {
    const isFirst = index === 0;

    if (!isFirst) {
      doc.addPage();
    }

    const startY = isFirst ? 110 : 30;

    const nomeColaborador = colaborador.usuario.nome || 'Não informado';
    const matriculaColaborador = colaborador.usuario.matricula || 'Não informado';
    const nomeFuncaoColaborador = colaborador.usuario.funcao || 'Não informado';
    const tituloSetorColaborador = colaborador.usuario.setor || 'Não informado';
    const quantidadeTotalColaborador = colaborador.usuario.quantidadeTotalColaborador || 'Não informado';

    doc.setFont('helvetica', 'bold');
    doc.text("Dados do Colaborador:", marginLeft, startY);
    doc.setFont('helvetica', 'normal');

    addLineDivider(doc, marginLeft, startY + 12);

    // Dados do Colaborador
    doc.setFont('helvetica', 'bold');
    doc.text(`Nome:`, marginLeft, startY + 32);
    doc.setFont('helvetica', 'normal');
    doc.text(nomeColaborador, marginLeft + 35, startY + 32);

    doc.setFont('helvetica', 'bold');
    doc.text("Matrícula:", marginLeft + marginSecondColumn, startY + 32);
    doc.setFont('helvetica', 'normal');
    doc.text(matriculaColaborador.toString(), marginLeft + marginSecondColumn + 50, startY + 32);

    doc.setFont('helvetica', 'bold');
    doc.text("Função:", marginLeft, startY + 52);
    doc.setFont('helvetica', 'normal');
    doc.text(nomeFuncaoColaborador, marginLeft + 43, startY + 52);

    doc.setFont('helvetica', 'bold');
    doc.text("Setor:", marginLeft + marginSecondColumn, startY + 52);
    doc.setFont('helvetica', 'normal');
    doc.text(tituloSetorColaborador, marginLeft + marginSecondColumn + 35, startY + 52);

    doc.setFont('helvetica', 'bold');
    doc.text("Quantidade Total de Produtos Retirados:", marginLeft, startY + 72);
    doc.setFont('helvetica', 'normal');
    doc.text(quantidadeTotalColaborador.toString(), marginLeft + 200, startY + 72);

    doc.setFont('helvetica', 'bold');
    doc.text('Período Analisado:', marginLeft + marginSecondColumn, startY + 72);
    doc.setFont('helvetica', 'normal');
    doc.text(`${dataInicio} - ${dataFim}`, marginLeft + marginSecondColumn + 95, startY + 72);

    addLineDivider(doc, marginLeft, startY + 82);

    const formattedData = colaborador.retiradas.map(retirada => [
      retirada.produto || 'Não informado',
      retirada.ca || 'Não informado',
      retirada.qtd_produto || 'Não informado',
      retirada.nomeFuncao || 'Não informado',
      retirada.responsavel_entrega || 'Não informado',
      retirada.data || 'Não informado',
    ]);

    const tableContent = {
      head: [headers],
      body: formattedData,
      startY: startY + 92,
      theme: 'grid',
      headStyles: { fillColor: [30, 81, 57] },
      styles: defaultConfigStylesTable,
      didDrawPage: function (data) {
        const pageNumber = doc.internal.getCurrentPageInfo().pageNumber;
        data.pageNumber = pageNumber;
        addFooter(doc, data, user);
      }
    };

    doc.autoTable(tableContent);
  });

  // caso não tenha nenhum colaborador
  if (tableData.length === 0) {
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(11);
    doc.text("Nenhuma retirada foi registrada para o período selecionado.", marginLeft, 120);
    doc.setFont('helvetica', 'normal');
    doc.text(`(${dataInicio} - ${dataFim})`, marginLeft, 135);
  }

  doc.save(`relatorio_ficha_epi_empresa_${empresa.nomeCompanhia}_${dayjs().format('YYYY-MM-DD_HHmm')}.pdf`);
}

export function exportSupplyMachineToPDF(dataToExport) {
  const { tableData, selectEmpresa: empresa, dadosUsuario, visibleColumns, reportName } = dataToExport;
  const { headers, formattedData } = extractDataToExport(visibleColumns, tableData);

  const doc = new jsPDF(orientation, unit, size);

  headerReport(doc, empresa, "RELATÓRIO DE ABASTECIMENTO DE MÁQUINA", 305);

  addLineDivider(doc, marginLeft, 110);

  const tableContent = {
    head: [headers],
    body: formattedData,
    startY: 120,
    theme: 'grid',
    fillColor: [255, 0, 0],
    headStyles: { fillColor: [30, 81, 57] },
    styles: defaultConfigStylesTable,
    didDrawPage: data => addFooter(doc, data, dadosUsuario.pessoa)
  };

  // if (visibleColumns.length >= 10) {
  //   // sum of columnWidths must be equal to 760
  //   tableContent.columnStyles = {
  //     0: { columnWidth: 80 },  // ÁREA
  //     1: { columnWidth: 80 },  // SETOR
  //     2: { columnWidth: 100 }, // MÁQUINA
  //     3: { columnWidth: 80 },  // ENDEREÇO
  //     4: { columnWidth: 55 },  // MOTOR
  //     5: { columnWidth: 90 },  // PRODUTO
  //     6: { columnWidth: 80 },  // ABASTECEDOR
  //     7: { columnWidth: 60 },  // REPOSIÇÃO
  //     8: { columnWidth: 70 },  // STR
  //     9: { columnWidth: 65 },  // DATA
  //   };
  // }

  doc.autoTable(tableContent);
  doc.save(`${reportName}_${dayjs().format('YYYY-MM-DD_HHmm')}.pdf`);
}

export function exportBalanceMachineToPDF(dataToExport) {
  const { tableData, selectedMaquina: maquina, selectedEmpresa: empresa, dadosUsuario, reportName } = dataToExport;

  const doc = new jsPDF(orientation, unit, size);

  const headers = ["Bandeja", "Nº Motor", "Código Motor", "CA", "Produto", "Cód. Produto", "Quantidade", "Categoria", "Unidade", "Capacidade"];

  const formattedData = tableData.map(item => {
    if (item?.isTray) {
      return [
        `Bandeja ${item.bandeja}`, '', '', '', '', '', '', '', ''
      ];
    } else {
      return [
        item?.bandeja || '',
        item?.numero_motor || '-',
        item?.codigo || '-',
        item?.produtoCa || '-',
        item?.produtoNome || '-',
        item?.produtoCodExterno || '-',
        item?.quantidade || 0,
        item?.categoria || '-',
        item?.unidade_medida || '-',
        item?.capacidade ? String(item.capacidade) : 0,
      ];
    }
  });

  headerReport(doc, empresa, `RELATÓRIO DE SALDO DE MÁQUINA: ${maquina?.nome}`, 380);

  doc.setFont('helvetica', 'bold');
  doc.text("Máquina Selecionada:", marginLeft, 110);
  doc.setFont('helvetica', 'normal');

  addLineDivider(doc, marginLeft, 120);

  const maquinaNome = maquina?.nome || 'Não informado';
  const maquinaTag = maquina?.tag_identificacao || 'Não informado';
  const maquinaDescricao = maquina?.descricao || 'Não informado';
  const maquinaQtdMotores = maquina?.qtd_motores ? String(maquina.qtd_motores) : 'Não informado';
  const maquinaLastSyncAt = maquina?.lastSyncAt || 'Não informado';
  const maquinaTipoContrato = maquina?.tipo_contrato || 'Não informado';

  doc.setFont('helvetica', 'bold');
  doc.text("Maquina:", marginLeft, 140);
  doc.setFont('helvetica', 'normal');
  doc.text(maquinaNome, marginLeft + 50, 140);

  doc.setFont('helvetica', 'bold');
  doc.text("Tag Identificação:", marginLeft + 580, 140);
  doc.setFont('helvetica', 'normal');
  doc.text(maquinaTag, marginLeft + 670, 140);

  doc.setFont('helvetica', 'bold');
  doc.text("Descrição:", marginLeft, 160);
  doc.setFont('helvetica', 'normal');
  doc.text(maquinaDescricao, marginLeft + 55, 160);

  doc.setFont('helvetica', 'bold');
  doc.text("Quantidade Motores:", marginLeft + 580, 160);
  doc.setFont('helvetica', 'normal');
  doc.text(maquinaQtdMotores, marginLeft + 685, 160);

  doc.setFont('helvetica', 'bold');
  doc.text("Última Sincronização:", marginLeft, 180);
  doc.setFont('helvetica', 'normal');
  doc.text(maquinaLastSyncAt, marginLeft + 110, 180);

  doc.setFont('helvetica', 'bold');
  doc.text("Tipo Contrato:", marginLeft + 580, 180);
  doc.setFont('helvetica', 'normal');
  doc.text(maquinaTipoContrato, marginLeft + 655, 180);

  addLineDivider(doc, marginLeft, 195);

  const tableContent = {
    head: [headers],
    body: formattedData,
    startY: 210,
    theme: 'grid',
    fillColor: [255, 0, 0],
    headStyles: { fillColor: [30, 81, 57] },
    styles: defaultConfigStylesTable,
    didDrawPage: function (data) {
      const pageNumber = doc.internal.getCurrentPageInfo().pageNumber;
      data.pageNumber = pageNumber;
      addFooter(doc, data, dadosUsuario.pessoa);
    }
  };
  doc.autoTable(tableContent);
  doc.save(`${reportName}_${maquina?.nome}_${dayjs().format('YYYY-MM-DD_HHmm')}.pdf`);
}

export function exportRetiradasToPDF(dataToExport) {
  const { tableData, selectEmpresa: empresa, dataInicio, dataFim, dadosUsuario, visibleColumns, reportName } = dataToExport;
  const { headers, formattedData } = extractDataToExport(visibleColumns, tableData);

  const totalRetiradas = tableData?.length.toString() || "N/A";

  const doc = new jsPDF(orientation, unit, size);

  headerReport(doc, empresa, "RELATÓRIO DE RETIRADAS", 180);

  addLineDivider(doc, marginLeft, 100);

  doc.setFont('helvetica', 'bold');
  doc.text('Período Analisado:', marginLeft, 120);
  doc.setFont('helvetica', 'normal');
  doc.text(`${dataInicio} - ${dataFim}`, marginLeft + 95, 120);

  doc.setFont('helvetica', 'bold');
  doc.text("Quantidade Total de Retiradas:", marginLeft + marginSecondColumn, 120);
  doc.setFont('helvetica', 'normal');
  doc.text(totalRetiradas, marginLeft + marginSecondColumn + 150, 120);

  addLineDivider(doc, marginLeft, 130);

  // const tiposAutenticacao = tableData.map(item => item.tipoAutenticacao);
  // const biometricPhotos = tableData.map(row => row.faceBiometricPhoto && row.faceBiometricPhoto);

  const tableContent = {
    head: [headers],
    body: formattedData,
    startY: 140,
    theme: 'grid',
    rowPageBreak: 'avoid',
    fillColor: [255, 0, 0],
    headStyles: { fillColor: [30, 81, 57] },
    styles: defaultConfigStylesTable,
    // didDrawCell: (data) => renderAuthenticationImage(doc, data, headers.indexOf('Autenticação'), tiposAutenticacao, biometricPhotos),
    didDrawPage: data => addFooter(doc, data, dadosUsuario.pessoa)
  };

  doc.autoTable(tableContent);
  doc.save(`${reportName}_${empresa.nomeCompanhia}_${dayjs().format('YYYY-MM-DD_HHmm')}.pdf`);
}

export function exportPendenciasRetiradasToPDF(dataToExport) {
  const { tableData, selectEmpresa: empresa, dadosUsuario, visibleColumns, reportName } = dataToExport;
  const { headers, formattedData } = extractDataToExport(visibleColumns, tableData);

  const doc = new jsPDF(orientation, unit, size);

  headerReport(doc, empresa, "RELATÓRIO DE PENDÊNCIAS DE RETIRADAS", 290);

  addLineDivider(doc, marginLeft, 100);

  const tableContent = {
    head: [headers],
    body: formattedData,
    startY: 110,
    theme: 'grid',
    fillColor: [255, 0, 0],
    headStyles: { fillColor: [30, 81, 57] },
    styles: defaultConfigStylesTable,
    didDrawPage: data => addFooter(doc, data, dadosUsuario.pessoa)
  };
  doc.autoTable(tableContent);
  doc.save(`${reportName}_${empresa.nomeCompanhia}_${dayjs().format('YYYY-MM-DD_HHmm')}.pdf`);
}

export function exportRetiradasExtrasToPDF(dataToExport) {
  const { tableData, selectEmpresa: empresa, dadosUsuario, visibleColumns, reportName } = dataToExport;
  const { headers, formattedData } = extractDataToExport(visibleColumns, tableData);

  const doc = new jsPDF(orientation, unit, size);

  headerReport(doc, empresa, "RELATÓRIO DE RETIRADAS EXTRAS", 235);

  addLineDivider(doc, marginLeft, 100);

  const tableContent = {
    head: [headers],
    body: formattedData,
    startY: 110,
    theme: 'grid',
    fillColor: [255, 0, 0],
    headStyles: { fillColor: [30, 81, 57] },
    styles: defaultConfigStylesTable,
    didDrawPage: data => addFooter(doc, data, dadosUsuario.pessoa)
  };

  doc.autoTable(tableContent);
  doc.save(`${reportName}_${empresa.nomeCompanhia}_${dayjs().format('YYYY-MM-DD_HHmm')}.pdf`);
}

export function exportRetiradaPorFuncToPDF(dataToExport) {
  const { tableData, selectEmpresa: empresa, colaborador, dadosUsuario, visibleColumns, reportName } = dataToExport;
  const { headers, formattedData } = extractDataToExport(visibleColumns, tableData);



  const doc = new jsPDF(orientation, unit, size);

  headerReport(doc, empresa, "RELATÓRIO DE RETIRADAS FUNCIONÁRIO", 280);

  // DADOS DO COLABORADOR (FICHA EPI)
  const nomeColaborador = colaborador?.nome || 'Não informado';
  const matriculaColaborador = colaborador?.matricula || 'Não informada';
  const funcaoColaborador = colaborador?.funcao?.nome || 'Não informada';
  const setorColaborador = colaborador?.setor?.nome || 'Não informado';

  doc.setFont('helvetica', 'bold');
  doc.text("Dados do Funcionário:", marginLeft, 112);
  doc.setFont('helvetica', 'normal');

  addLineDivider(doc, marginLeft, 124);

  doc.setFont('helvetica', 'bold');
  doc.text("Nome:", marginLeft, 142);
  doc.setFont('helvetica', 'normal');
  doc.text(nomeColaborador, marginLeft + 35, 142);

  doc.setFont('helvetica', 'bold');
  doc.text("Matrícula:", marginLeft + marginSecondColumn, 142);
  doc.setFont('helvetica', 'normal');
  doc.text(matriculaColaborador, marginLeft + marginSecondColumn + 50, 142);

  doc.setFont('helvetica', 'bold');
  doc.text("Função:", marginLeft, 162);
  doc.setFont('helvetica', 'normal');
  doc.text(funcaoColaborador, marginLeft + 45, 162);

  doc.setFont('helvetica', 'bold');
  doc.text("Setor:", marginLeft + marginSecondColumn, 162);
  doc.setFont('helvetica', 'normal');
  doc.text(setorColaborador, marginLeft + marginSecondColumn + 33, 162);

  addLineDivider(doc, marginLeft, 174);

  const tableContent = {
    head: [headers],
    body: formattedData,
    startY: 194,
    theme: 'grid',
    fillColor: [255, 0, 0],
    headStyles: { fillColor: [30, 81, 57] },
    styles: defaultConfigStylesTable,
    didDrawPage: data => addFooter(doc, data, dadosUsuario.pessoa)
  };

  doc.autoTable(tableContent);
  doc.save(`${reportName}_${nomeColaborador}_${dayjs().format('YYYY-MM-DD_HHmm')}.pdf`);
}

export function exportSaidasProdutosToPDF(dataToExport) {
  const { tableData, selectEmpresa: empresa, dadosUsuario, visibleColumns, reportName } = dataToExport;
  const { headers, formattedData } = extractDataToExport(visibleColumns, tableData);

  const doc = new jsPDF(orientation, unit, size);

  headerReport(doc, empresa, "RELATÓRIO DE SAÍDAS DE PRODUTOS", 280);

  addLineDivider(doc, marginLeft, 110);

  const tableContent = {
    head: [headers],
    body: formattedData,
    startY: 130,
    theme: 'grid',
    fillColor: [255, 0, 0],
    headStyles: { fillColor: [30, 81, 57] },
    styles: defaultConfigStylesTable,
    didDrawPage: data => addFooter(doc, data, dadosUsuario.pessoa)
  };

  doc.autoTable(tableContent);
  doc.save(`${reportName}_${dayjs().format('YYYY-MM-DD_HHmm')}.pdf`);
}

export function exportSaidasProdutosPorFuncToPDF(dataToExport) {
  const { tableData, selectedEmpresa: empresa, dadosUsuario, reportName, isDaily, dataInicio, dataFim } = dataToExport;

  const doc = new jsPDF(orientation, unit, size);

  const headers = isDaily ? ["Produto", "CA", "Quantidade", "Data Entrega"] : ["Produto", "CA", "Quantidade", "Saídas"];

  headerReport(doc, empresa, "RELATÓRIO DE SAÍDAS DE PRODUTOS POR RESPONSÁVEL", 380);

  // percorrer cada responsável
  tableData.forEach((responsavel, index) => {
    const isFirst = index === 0;

    if (!isFirst) {
      doc.addPage();
    }

    const startY = isFirst ? 110 : 30;

    const nomeResponsavel = responsavel.nomeResponsavel || 'Não informado';
    const matriculaResponsavel = responsavel.matriculaResponsavel || 'Não informado';
    const nomeFuncaoResponsavel = responsavel.nomeFuncaoResponsavel || 'Não informado';
    const tituloSetorResponsavel = responsavel.tituloSetorResponsavel || 'Não informado';
    const quantidadeTotalResponsavel = responsavel.quantidadeTotalResponsavel || 'Não informado';

    addLineDivider(doc, marginLeft, startY);

    // Dados do Responsavel
    doc.setFont('helvetica', 'bold');
    doc.text(`Responsável: ${nomeResponsavel}`, marginLeft, startY + 20);
    doc.setFont('helvetica', 'normal');

    doc.setFont('helvetica', 'bold');
    doc.text("Matrícula:", marginLeft + marginSecondColumn, startY + 20);
    doc.setFont('helvetica', 'normal');
    doc.text(matriculaResponsavel.toString(), marginLeft + marginSecondColumn + 50, startY + 20);

    doc.setFont('helvetica', 'bold');
    doc.text("Função:", marginLeft, startY + 40);
    doc.setFont('helvetica', 'normal');
    doc.text(nomeFuncaoResponsavel, marginLeft + 45, startY + 40);

    doc.setFont('helvetica', 'bold');
    doc.text("Setor:", marginLeft + marginSecondColumn, startY + 40);
    doc.setFont('helvetica', 'normal');
    doc.text(tituloSetorResponsavel, marginLeft + marginSecondColumn + 35, startY + 40);

    doc.setFont('helvetica', 'bold');
    doc.text("Quantidade Total de Produtos Entregue:", marginLeft, startY + 60);
    doc.setFont('helvetica', 'normal');
    doc.text(quantidadeTotalResponsavel.toString(), marginLeft + 200, startY + 60);

    doc.setFont('helvetica', 'bold');
    doc.text('Período Analisado:', marginLeft + marginSecondColumn, startY + 60);
    doc.setFont('helvetica', 'normal');
    doc.text(`${dataInicio} - ${dataFim}`, marginLeft + marginSecondColumn + 95, startY + 60);

    addLineDivider(doc, marginLeft, startY + 70);

    const formattedData = isDaily
      ? responsavel.produtos.map(produto => [
        produto.produtoCa || 'Não informado',
        produto.produtoNome || 'Não informado',
        produto.produtoCodExterno || 'Não informado',
        produto.quantidadeTotal || 'Não informado',
        produto.dataSaida || 'Não informado',
      ])
      : responsavel.produtos.map(produto => [
        produto.produtoCa || 'Não informado',
        produto.produtoNome || 'Não informado',
        produto.produtoCodExterno || 'Não informado',
        produto.quantidadeTotal || 'Não informado',
        produto.numeroSaidas || 'Não informado',
      ]);

    const tableContent = {
      head: [headers],
      body: formattedData,
      startY: startY + 80,
      theme: 'grid',
      headStyles: { fillColor: [30, 81, 57] },
      styles: defaultConfigStylesTable,
      didDrawPage: function (data) {
        // levar em consideração do data.pageNumber o index do responsável, mas também caso a tabela do responsavel atual tenha mais de uma pagina
        // get how much pages the table will have and the actual page
        const pageNumber = doc.internal.getCurrentPageInfo().pageNumber;
        data.pageNumber = pageNumber;
        addFooter(doc, data, dadosUsuario.pessoa);
      }
    };

    doc.autoTable(tableContent);
  });

  doc.save(`${reportName}_${dayjs().format('YYYY-MM-DD_HHmm')}.pdf`);
}

export function defaultExportDataFromTableToPDF(dataToExport) {
  const { tableData, columns, selectedEmpresa: empresa, dadosUsuario, reportName, reportTitle } = dataToExport;
  const { headers, formattedData } = extractDataToExport(columns, tableData);

  const doc = new jsPDF(orientation, unit, size);

  // calcular dinamicamente a width da linha que fica abaixo do título
  const strokeWidth = reportTitle.length * 8.3;
  headerReport(doc, empresa, reportTitle, strokeWidth);

  const tableContent = {
    head: [headers],
    body: formattedData,
    startY: empresa ? 105 : 90,
    theme: 'grid',
    fillColor: [255, 0, 0],
    headStyles: { fillColor: [30, 81, 57] },
    styles: defaultConfigStylesTable,
    didDrawPage: (data) => addFooter(doc, data, dadosUsuario.pessoa)
  };

  doc.autoTable(tableContent);
  doc.save(`${reportName}_${dayjs().format('YYYY-MM-DD_HHmm')}.pdf`);
}

export function exportToExcel(reportName, apiRef) {
  const visibleColumns = apiRef.current.getVisibleColumns().filter((column) => column.headerName !== "Ações");
  const rowModels = apiRef.current.getRowModels();
  const rows = Array.from(rowModels.values());

  const { dataWithHeaders } = extractDataToExportJson(visibleColumns, rows);

  const ws = XLSX.utils.json_to_sheet(dataWithHeaders);

  // Calculate column widths based on both header length and content length
  const columnWidths = visibleColumns.map((column) => {
    const headerWidth = column.headerName.length;

    // Find the longest value in the column data
    const maxContentWidth = Math.max(
      ...rows.map((row) => {
        const value = getValueFromColumn(column, row);
        return value ? String(value).length : 0;
      })
    );

    // Returning a calculated width, adding a buffer (adjust this value if necessary)
    return { wch: Math.max(headerWidth, maxContentWidth) + 2 };
  });

  // Set column widths in the worksheet
  ws["!cols"] = columnWidths;

  // Create workbook and add sheet with data
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

  // Write workbook to buffer in xlsx format
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  // Convert buffer to Blob with specified MIME type
  const data = new Blob([excelBuffer], { type: fileType });

  // Save data as a file with specified filename
  FileSaver.saveAs(data, `${reportName}_${dayjs().format('YYYY-MM-DD_HHmm')}` + fileExtension);
};

export function exportToExcelWithoutPaginate(reportName, columns, rows) {
  const { dataWithHeaders } = extractDataToExportJson(columns, rows);

  // Convert data with headers to sheet
  const ws = XLSX.utils.json_to_sheet(dataWithHeaders);

  // Create workbook and add sheet with data
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

  // Write workbook to buffer in xlsx format
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  // Convert buffer to Blob with specified MIME type
  const data = new Blob([excelBuffer], { type: fileType });

  // Save data as a file with specified filename
  FileSaver.saveAs(data, `${reportName}_${dayjs().format('YYYY-MM-DD_HHmm')}` + fileExtension);
};

export function exportToCsvWithoutPaginate(reportName, columns, rows) {
  const { headers, formattedData } = extractDataToExport(columns, rows);

  // Formata os dados para CSV
  const csvContent = [headers, ...formattedData].join('\n');

  // Cria o Blob e faz o download
  const fileExtension = '.csv';
  const csvBlob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  FileSaver.saveAs(csvBlob, `${reportName}_${dayjs().format('YYYY-MM-DD_HHmm')}${fileExtension}`);
}

export const getIconAuthType = (tipo) => {
  switch (tipo) {
    case 'biometria':
      return <ClientManagementIcon fontSize='small' color='primary' />;
    case 'senha':
      return <GoodPincodeIcon fontSize='small' color='primary' />;
    case 'cracha':
      return <NameTagIcon fontSize='small' color='primary' />;
    default:
      return <Info fontSize='small' color='primary' />;
  }
};

export const getImageSrc = (photo) => {
  const prefixes = [
    "data:image/jpeg;base64,",
    "data:image/png;base64,",
    "data:image/jpg;base64,"
  ];

  // Verifica se o base64 já tem algum prefixo válido
  if (prefixes.some(prefix => photo.startsWith(prefix))) {
    return photo;
  }

  return `${prefixes[0]}${photo}`;
};

export const downloadBase64AsJpg = (event, photoUrl, fileName) => {
  event.preventDefault();
  const anchor = document.createElement('a');
  anchor.href = photoUrl;
  anchor.download = `${fileName}_captura.jpg`;
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
};

function calculateImageDimensions(imgProps, maxWidth, maxHeight) {
  const originalWidth = imgProps.width;
  const originalHeight = imgProps.height;

  let imgWidth = originalWidth;
  let imgHeight = originalHeight;
  const aspectRatio = originalWidth / originalHeight;

  if (originalWidth > maxWidth || originalHeight > maxHeight) {
    if (aspectRatio >= 1) {
      // Imagem mais larga que alta
      imgWidth = maxWidth;
      imgHeight = maxWidth / aspectRatio;

      if (imgHeight > maxHeight) {
        imgHeight = maxHeight;
        imgWidth = maxHeight * aspectRatio;
      }
    } else {
      // Imagem mais alta que larga
      imgHeight = maxHeight;
      imgWidth = maxHeight * aspectRatio;

      if (imgWidth > maxWidth) {
        imgWidth = maxWidth;
        imgHeight = maxWidth / aspectRatio;
      }
    }
  }

  return { width: imgWidth, height: imgHeight };
}

export function renderAuthenticationImage(doc, data, indexOfAuthCell, tiposAutenticacao, imagesSrc) {
  if (indexOfAuthCell >= 0 && data.column.index === indexOfAuthCell && data.cell.section === 'body') {
    const cellPadding = data.cell.padding('vertical');
    const authType = tiposAutenticacao[data.row.index];

    // const label = authType === 'biometria' ? 'Biometria' : authType === 'senha' ? 'Senha' : '';
    // doc.text(label, data.cell.x + (cellPadding / 2), data.cell.y + cellPadding + 4);

    if (authType === 'biometria' && imagesSrc[data.row.index]) {
      const imageSrc = imagesSrc[data.row.index];
      if (imageSrc.includes('/9j/')) {
        const img = new Image();
        img.src = getImageSrc(imageSrc);
        const dim = (data.cell.height * 0.85) - cellPadding;
        doc.addImage(img.src, data.cell.x + (cellPadding / 2), data.cell.y + 8 + cellPadding, dim, dim);
      }
    }
  }
}
