import { DialogActions, Button, CircularProgress, Stack, Typography, Divider } from '@mui/material'
import { grey } from '@mui/material/colors'

export function CommonDialogActionsStepsForm({ steps, activeStep, handleBack, handleNext, errors, isLoading, setOpen, isEditAction = false, disableSubmit = false }) {
  return (
    <>
      {Object.keys(errors).length > 0 && (
        <Stack justifyContent="end" direction='row'>
          <Typography variant="caption" color="error" sx={{ px: 2, mt: 1 }}>
            Ao menos um campo não foi preenchido corretamente
          </Typography>
        </Stack>
      )}

      <DialogActions>
        <Button onClick={() => setOpen(false)} variant="contained">
          {disableSubmit ? 'Fechar' : 'Cancelar'}
        </Button>

        <Divider orientation="vertical" flexItem sx={{ ml: 1 }} />

        {activeStep !== 0 && (
          <Button onClick={handleBack} variant="outlined" type="button">
            Voltar
          </Button>
        )}

        {(!isEditAction && activeStep !== steps.length - 1) || (isEditAction && activeStep !== steps.length) ? (
          <Button
            onClick={handleNext}
            variant="contained"
            type="button" disabled={isLoading}
            startIcon={isLoading && <CircularProgress size={16} sx={{ color: grey[400] }} />}
          >
            {isLoading ? 'Carregando...' : 'Próximo'}
          </Button>
        ) :
          !disableSubmit && (
            <Button
              type='submit'
              variant='contained'
              disabled={Object.keys(errors).length > 0 || isLoading}
              startIcon={isLoading && <CircularProgress size={16} sx={{ color: grey[400] }} />}
            >
              {isLoading ? 'Salvando...' : 'Salvar'}
            </Button>
          )}
      </DialogActions>
    </>
  )
}
