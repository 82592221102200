import { Tooltip, IconButton } from '@mui/material';
import { green, grey, red, yellow } from '@mui/material/colors';
import { Check, Downloading, Error, HelpOutline } from '@mui/icons-material';

const statusMapping = {
  gerado: {
    title: 'Arquivo gerado com sucesso',
    icon: <Check sx={{ fontSize: 22, color: green[400] }} />,
  },
  erro: {
    title: 'Falha ao gerar arquivo',
    icon: <Error sx={{ fontSize: 22, color: red[400] }} />,
  },
  solicitado: {
    title: 'Solicitação criada. Aguardando processamento',
    icon: <Downloading sx={{ fontSize: 22, color: yellow[800] }} />,
  },
  default: {
    title: 'Status desconhecido',
    icon: <HelpOutline sx={{ fontSize: 22, color: grey[500] }} />,
  },
};

export function ExportStatusCell({ status }) {
  const { title, icon } = statusMapping[status] || statusMapping.default;

  return (
    <Tooltip title={title}>
      <IconButton size="small" disableTouchRipple sx={{ cursor: 'default' }}>
        {icon}
      </IconButton>
    </Tooltip>
  );
}