import { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  TextField,
  MenuItem,
  Typography,
  Stack,
  Tooltip,
  IconButton,
  Autocomplete,
} from '@mui/material';
import { Controller } from "react-hook-form";
import { HelpIcon } from '../../../../../common/Icons';
import { getFormErrorMessage } from '../../../../../../configs/functions';

export function SelectExportTypeStep({ tiposExportacao, isLoadingCompanyData, control, setValue, getValues, clearErrors, errors }) {
  const [selectedExportType, setSelectedExportType] = useState(null);

  const [campos, setCampos] = useState([]);
  const [camposExtras, setCamposExtras] = useState([]);

  useEffect(() => {
    if (selectedExportType?.camposExtras) {
      try {
        const parsedCamposExtras = JSON.parse(selectedExportType.camposExtras);
        const entries = Object.entries(parsedCamposExtras);
        setCamposExtras(entries);
      } catch (error) {
        console.error('Erro ao parsear camposExtras:', error);
        setCamposExtras([]);
      }
    }

    if (selectedExportType?.campos) {
      try {
        const parsedCampos = JSON.parse(selectedExportType.campos);
        const keysArray = Object.keys(parsedCampos).filter((key) => parsedCampos[key] === true);
        setCampos(keysArray);
      } catch (error) {
        console.error('Erro ao parsear campos:', error);
        setCampos([]);
      }
    }
  }, [selectedExportType]);


  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={2}
    >
      <Grid item xs={12}>
        <Stack direction={'row'} alignItems={'center'} gap={1}>
          <Typography variant="h6" color="textPrimary">
            Configuração de Exportação
          </Typography>

          <Tooltip title="As configurações de exportação são utilizadas para definir como os dados serão exportados.">
            <IconButton><HelpIcon /></IconButton>
          </Tooltip>
        </Stack>
      </Grid>

      <Grid item xs={12} md={6}>
        <Controller
          name='id_tipo_exportacao'
          control={control}
          rules={{ required: true }}
          render={({ field: { ref, onChange, value, ...field } }) => (
            <TextField
              {...field}
              select
              fullWidth
              value={value || ''}
              sx={{ mt: 1 }}
              label="Configuração"
              onChange={(e) => {
                const value = e.target.value;

                setValue('id_tipo_exportacao', value, { shouldDirty: true })

                const selectedExportType = tiposExportacao.find((item) => item.id === value);

                setSelectedExportType(selectedExportType);
                setValue('nome_tipo_exportacao', selectedExportType?.nome || '');

                if (value) {
                  clearErrors("id_tipo_exportacao");
                }
              }}
              error={!!errors.id_tipo_exportacao}
              helperText={getFormErrorMessage(errors, 'id_tipo_exportacao')}
            >
              {tiposExportacao.length > 0 && <MenuItem value="">Selecione...</MenuItem>}

              {!isLoadingCompanyData ?
                (tiposExportacao.length > 0 ?
                  (tiposExportacao.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.nome}
                    </MenuItem>
                  ))
                  ) : <MenuItem disabled>Nenhum disponível</MenuItem>
                ) : <MenuItem disabled>Carregando...</MenuItem>
              }
            </TextField>
          )}
        />

        <Typography variant="subtitle2" color="textSecondary" mt={1}>
          <a href="configuracoes-exportacao" style={{ color: 'inherit', fontWeight: 'bold' }}>
            Clique aqui
          </a> {' '}
          para criar uma nova configuração de exportação.
        </Typography>
      </Grid>

      <Grid item xs={12} md={12}>
        <Box sx={{ width: "100%", backgroundColor: "#fff", p: 2, borderRadius: 1 }}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item xs={12}>
              <Typography variant="h6" color="textSecondary">
                Detalhes da Configuração
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <TextField
                margin='dense'
                label='Nome'
                placeholder='Nome'
                fullWidth
                variant="outlined"
                disabled
                value={selectedExportType?.nome || ""}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                margin='dense'
                label='Tabela fonte dos Registros'
                placeholder='Tabela fonte dos Registros'
                fullWidth
                variant="outlined"
                disabled
                value={selectedExportType?.tipoRegistro.split('\\').pop() || ""}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                margin='dense'
                label='Descrição'
                placeholder='Descrição'
                fullWidth
                variant="outlined"
                multiline
                rows={2}
                disabled
                value={selectedExportType?.descricao || ""}
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                margin='dense'
                label='Tipo de Arquivo'
                fullWidth
                variant="outlined"
                disabled
                value={selectedExportType?.tipoArquivo || ""}
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                margin='dense'
                label='Delimitador do Arquivo'
                fullWidth
                variant="outlined"
                disabled
                value={selectedExportType?.configArquivo
                  ? JSON.parse(selectedExportType?.configArquivo).delimitador
                  : ""
                }
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                margin='dense'
                label='Codificador do Arquivo'
                fullWidth
                variant="outlined"
                disabled
                value={
                  selectedExportType?.configArquivo
                    ? JSON.parse(selectedExportType?.configArquivo).codificador
                    : ""
                }
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body1" color="textSecondary" mb={1}>
                Campos
              </Typography>

              <Autocomplete
                multiple
                freeSolo
                options={campos}
                getOptionLabel={(option) => option}
                value={campos}
                disabled
                noOptionsText="Nenhum campo encontrado"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                  />
                )}
                sx={{
                  // prevent the input break to a new line on hover
                  '& .MuiOutlinedInput-root': {
                    '& .MuiOutlinedInput-input': {
                      boxSizing: 'border-box',
                    },
                  },
                }}
              />
            </Grid>

            <Grid container item xs={12} spacing={1}>
              <Grid item xs={12}>
                <Typography variant="body1" color="textSecondary">
                  Campos Extras
                </Typography>
              </Grid>

              {camposExtras.length > 0 ? camposExtras.map(([key, value], index) => (
                <Grid container item xs={12} key={index} spacing={1}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      margin="dense"
                      label="Campo"
                      fullWidth
                      variant="outlined"
                      disabled
                      value={key || ''}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      margin="dense"
                      label="Valor"
                      fullWidth
                      variant="outlined"
                      disabled
                      value={value || ''}
                    />
                  </Grid>
                </Grid>
              )) : (
                <Grid item xs={12}>
                  <Typography variant="body2" color="textSecondary">
                    Nenhum campo extra configurado.
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  )
}