import { useState, useEffect } from 'react';
import {
    Box,
    FormControl,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    DialogActions,
    Button,
    Divider,
    CircularProgress,
    Stepper,
    Step,
    StepButton,
    StepLabel,
    useMediaQuery,
} from '@mui/material';
import { useForm } from "react-hook-form";
import { fillInputErrors } from '../../../../../../configs/functions';
import { useCommonItems } from '../../../../../../contexts/CommonItensProvider';
import { manipulaTipoExportacao } from '../../configs/functions';
import { IdentityStep } from './IdentityStep';
import { FileTypeConfigStep } from './FileTypeConfigStep';
import { RegisterTypeConfigStep } from './RegisterTypeConfigStep';
import { FiltersAndExtraFieldsStep } from './FiltersAndExtraFieldsStep';
import { useQueryClient } from '@tanstack/react-query';
import { validateRequiredFields } from '../../../../../../utils/validate-required-fields';

const steps = ['Identificação', 'Arquivo', 'Dados', 'Filtros e Campos Extras'];

export function ExportTypeForm({ open, setOpen, selectedExportType, queryKey }) {
    const queryClient = useQueryClient();
    const { exibirDialog, handleCloseDialog, exibirAlerta } = useCommonItems();

    const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));

    const [isLoading, setIsLoading] = useState(false);
    const [activeStep, setActiveStep] = useState(0);

    const isEditAction = !!selectedExportType;

    const {
        register,
        handleSubmit,
        getValues,
        setValue,
        setError,
        reset,
        control,
        clearErrors,
        formState: { dirtyFields, errors },
    } = useForm();

    const onSubmit = async (data) => {
        console.log('onSubmit ~ data:', data);

        let formDataToSend = {};
        setIsLoading(true);
        exibirDialog('Salvando. Aguarde...');

        if (isEditAction) {
            const dirtyFieldKeys = Object.keys(dirtyFields);

            const dirtyData = dirtyFieldKeys.reduce((acc, key) => {
                if (data.hasOwnProperty(key)) {
                    acc[key] = data[key];
                }
                return acc;
            }, {});

            formDataToSend = dirtyData;

            formDataToSend.id = selectedExportType.id;
        } else {
            delete data.id;

            if (data.id_setor === "") {
                delete data.id_setor;
            }

            formDataToSend = data;
        }

        const camposFormatted = data.campos.reduce((acc, item) => {
            acc[item] = true;
            return acc;
        }, {});

        // campos extras adicionados manualmente, para serem exibidos no arquivo
        const camposExtras = data.campos_extras.reduce((acc, item) => {
            if (!item.nome) return acc;

            acc[item.nome] = item.valor;
            return acc;
        }, {});

        formDataToSend.campos = camposFormatted;
        formDataToSend.campos_extras = camposExtras;

        console.log('onSubmit ~ formDataToSend:', formDataToSend);

        try {
            const res = await manipulaTipoExportacao(formDataToSend, isEditAction);
            exibirAlerta('Sucesso', res.data.message, 'success');
            setOpen(false);


            // invalidate e refetch para atualizar os dados
            queryClient.invalidateQueries({ queryKey });
        } catch (error) {
            console.error(error);
            let errorMessage = "Erro ao cadastrar Config. Exportação.";
            if (error.response) {
                errorMessage = error.response.data.message
                fillInputErrors(error, setError);
            }
            exibirAlerta('Erro', errorMessage, 'error');
        } finally {
            setIsLoading(false);
            handleCloseDialog();
        }
    };

    async function validateFieldsStepFileType() {
        const errors = {};

        if (!getValues('tipo_arquivo')) {
            errors.tipo_arquivo = 'Campo obrigatório';
        }

        // se o tipo_arquivo for csv, o delimitador é obrigatório
        // se o tipo_arquivo for txt, o delimiter e o codificador são obrigatórios
        if (getValues('tipo_arquivo') === 'csv' && !getValues('config_arquivo.delimitador')) {
            errors['config_arquivo.delimitador'] = 'Campo obrigatório';
        }

        if (getValues('tipo_arquivo') === 'txt' && !getValues('config_arquivo.delimitador')) {
            errors['config_arquivo.delimitador'] = 'Campo obrigatório';
        }

        if (getValues('tipo_arquivo') === 'txt' && !getValues('config_arquivo.codificador')) {
            errors['config_arquivo.codificador'] = 'Campo obrigatório';
        }

        if (Object.keys(errors).length > 0) {
            Object.keys(errors).forEach((key) => {
                setError(key, { type: 'manual', message: errors[key] });
            });

            return false;
        }

        clearErrors();
        return true;
    }

    const handleNext = async (event) => {
        event.preventDefault();
        switch (activeStep) {
            case 0:
                if (await validateRequiredFields(getValues, setError, clearErrors, ['nome'])) {
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }
                break;
            case 1:
                if (await validateFieldsStepFileType()) {
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }
                break;
            case 2:
                if (await validateRequiredFields(getValues, setError, clearErrors, ['tipo_registro', 'campos'])) {
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }
                break;
            default:
                break;
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (pos) => {
        if (pos < activeStep) {
            setActiveStep(pos)
        }
    }

    useEffect(() => {
        setActiveStep(0);

        if (open) {
            const defaultValues = {
                id: selectedExportType?.id || '',
                nome: selectedExportType?.nome || '',
                tipo_arquivo: selectedExportType?.tipo_arquivo || '',
                status: selectedExportType?.status || 1,
                tipo_registro: 'retirada',
                config_fixas: selectedExportType?.config_fixas || [{ nome: '', valor: '' }],
                config_arquivo: selectedExportType?.config_arquivo || { delimitador: '', codificador: '' },
                campos: selectedExportType?.campos || [],
                campos_extras: selectedExportType?.campos_extras || [{ nome: '', valor: '' }],
            };

            reset(defaultValues);
        } else {
            reset();
        }
    }, [open, reset, selectedExportType]);

    return (
        <Dialog open={open} fullWidth maxWidth={"md"}>
            <form onSubmit={handleSubmit(onSubmit)}>
                {isEditAction
                    ? <DialogTitle>Editando Configuração de Exportação <strong>{selectedExportType.nome}</strong></DialogTitle>
                    : <DialogTitle>Cadastrar nova Configuração de Exportação</DialogTitle>
                }
                <DialogContent>
                    <DialogContentText sx={{ mb: 2 }}>
                        {isEditAction
                            ? 'Preencha corretamente os dados.'
                            : 'Preencha corretamente os dados abaixo para cadastrar um nova Configuração.'
                        }
                    </DialogContentText>

                    <Stepper activeStep={activeStep} sx={{ px: { xs: 2, sm: 10 }, py: 2, overflowX: 'scroll' }} orientation={isMobile ? 'vertical' : 'horizontal'}>
                        {steps.map((label, index) => (
                            <Step key={label}>
                                <StepButton color="inherit" onClick={() => handleStep(index)}>
                                    <StepLabel>{label}</StepLabel>
                                </StepButton>
                            </Step>
                        ))}
                    </Stepper>

                    <Box sx={{ p: 2, backgroundColor: "#fafafa" }} >
                        <FormControl variant="standard" fullWidth >
                            {activeStep === 0 && (
                                <IdentityStep
                                    register={register}
                                    errors={errors}
                                    setValue={setValue}
                                    control={control}
                                />
                            )}
                            {activeStep === 1 && (
                                <FileTypeConfigStep
                                    errors={errors}
                                    control={control}
                                    setValue={setValue}
                                    getValues={getValues}
                                    clearErrors={clearErrors}
                                />
                            )}
                            {activeStep === 2 && (
                                <RegisterTypeConfigStep
                                    errors={errors}
                                    control={control}
                                    getValues={getValues}
                                />
                            )}
                            {activeStep === 3 && (
                                <FiltersAndExtraFieldsStep
                                    getValues={getValues}
                                    register={register}
                                    errors={errors}
                                    control={control}
                                    setValue={setValue}
                                />
                            )}
                        </FormControl>
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => setOpen(false)} variant="contained">
                        Cancelar
                    </Button>

                    <Divider orientation="vertical" flexItem />

                    {activeStep !== 0 && (
                        <Button onClick={handleBack} variant="outlined" type="button">
                            Voltar
                        </Button>
                    )}
                    {activeStep !== steps.length - 1 ? (
                        <Button onClick={handleNext} variant="contained" type="button" disabled={isLoading}>
                            {!isLoading
                                ? 'Próximo'
                                : <><CircularProgress size={16} sx={{ marginRight: 1 }} />Carregando</>
                            }
                        </Button>
                    ) : (
                        <Button type="submit" variant="contained">
                            Salvar
                        </Button>
                    )}
                </DialogActions>
            </form>
        </Dialog>
    );
}