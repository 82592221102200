import { useState, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Helmet } from 'react-helmet-async';
import {
  Grid,
  Breadcrumbs,
  Typography,
  Button,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Table,
  IconButton,
  Stack
} from '@mui/material';
import { Add, Home as HomeIcon } from '@mui/icons-material';
import PageTitle from '../../../common/PageTitle';
import LinkNavegacao from '../../../common/Link';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { EditIcon, ProductDocumentsIcon, TrashIcon } from '../../../common/Icons';
import ConfirmDialog from '../../../common/ConfirmDialog';
import { checkPermissionsAndRedirect, hasPermission } from '../../../../configs/functions';
import { EmptyTableRow } from '../../../common/EmptyTableRow';
import { SkeletonTableRow } from '../../../common/SkeletonTableRow';
import { listaEmpresas } from '../companies/configs/functions';
import { CustomTablePagination } from '../../../common/CustomTablePagination';
import { deletaTipoDocumento, listaTiposDocumento } from './configs/functions';
import { DocumentTypeForm } from './DocumentTypeForm';
import { SelectEmpresaFilter } from '../../../common/SelectEmpresaFilter';
import { useAppContext } from '../../../../contexts/AppContext';

const DocumentTypeRow = ({ data, handleOpenEditForm, handleOpenDeleteDialog }) => {
  return (
    <TableRow>
      <TableCell>{data.titulo}</TableCell>
      <TableCell>{data.observacao}</TableCell>
      <TableCell align='right' sx={{ minWidth: 110 }}>
        {hasPermission(["admin", "admin_tipoDocumento", "update_tipoDocumento"]) && (
          <IconButton aria-label="Editar" onClick={() => handleOpenEditForm(data)}>
            <EditIcon />
          </IconButton>
        )}
        {hasPermission(["admin", "admin_tipoDocumento", "delete_tipoDocumento"]) && (
          <IconButton aria-label="Excluir" onClick={() => handleOpenDeleteDialog(data)}>
            <TrashIcon />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
};

export function ListOfDocumentType() {
  const queryClient = useQueryClient();

  const { exibirAlerta } = useCommonItems();
  const { getEmpresaIdSession, setEmpresaIdSession } = useAppContext();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [selectedTipoDocumento, setSelectedTipoDocumento] = useState(null);
  const [openForm, setOpenForm] = useState(false);
  const [deleteOpenDialog, setOpenDeleteDialog] = useState(false);
  const [selectedEmpresaId, setSelectedEmpresaId] = useState(getEmpresaIdSession());

  const queryKey = ['listaTiposDocumento', selectedEmpresaId, page, rowsPerPage];

  const { data: listaTiposDocumentoData, isPending: listaTiposDocumentoIsLoading } = useQuery({
    queryKey,
    queryFn: () =>
      listaTiposDocumento({
        id_empresa: selectedEmpresaId,
        limit: rowsPerPage,
        offset: page * rowsPerPage,
      }),
    enabled: !!selectedEmpresaId,
  });

  const { data: queryEmpresasData } = useQuery({
    queryKey: ['listaEmpresas'],
    queryFn: async () => {
      const response = await listaEmpresas();
      const empresasData = response.data.data;

      const selectedEmpresaIdSession = getEmpresaIdSession();

      if (selectedEmpresaIdSession) {
        setSelectedEmpresaId(selectedEmpresaIdSession);
      }
      else if (empresasData.length > 0) {
        setSelectedEmpresaId(empresasData[0].id);
        setEmpresaIdSession(empresasData[0].id);
      }

      return response;
    },
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    const requiredPermissionsView = ['admin', 'admin_tipoDocumento', 'list_tipoDocumento'];
    checkPermissionsAndRedirect(requiredPermissionsView);
  }, []);

  const tiposDocumento = listaTiposDocumentoData?.data?.data ?? [];
  const numTotalItems = listaTiposDocumentoData?.data?.numero_total ?? 0;

  const empresas = queryEmpresasData?.data?.data ?? [];

  const handleEdit = (item) => {
    setSelectedTipoDocumento(item);
    setOpenForm(true);
  };

  const handleOpenDeleteDialog = (item) => {
    setSelectedTipoDocumento(item);
    setOpenDeleteDialog(true);
  };

  const { mutateAsync: handleDelete } = useMutation({
    mutationFn: deletaTipoDocumento,
    onSuccess: (response, id) => {
      const message = response.data.message ?? 'Tipo de Documento inativado com sucesso';
      exibirAlerta('Sucesso', message, 'success');

      const cached = queryClient.getQueryData(queryKey);
      const updatedData = {
        ...cached,
        data: {
          ...cached.data,
          data: cached.data.data.filter((item) => item.id !== id),
        },
      };
      queryClient.setQueryData(queryKey, updatedData);
    },
    onError: (error) => {
      const message = error.response?.data?.message ?? 'Erro ao executar operação';
      exibirAlerta('Erro', message, 'error');
    },
    onSettled: () => {
      setOpenDeleteDialog(false);
      setSelectedTipoDocumento(null);
    },
  });

  return (
    <>
      <Helmet title="Tipos de Documentos" defer={false} />

      <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={3}>
        <Grid item xs={12}>
          <Breadcrumbs>
            <LinkNavegacao to="/">
              <HomeIcon fontSize="small" />
            </LinkNavegacao>
            <Typography variant="span">Gerenciamento</Typography>
            <LinkNavegacao to="/gerenciamento/organizacao">Organização</LinkNavegacao>
            <Typography variant="span">Tipos de Documentos</Typography>
          </Breadcrumbs>
          <PageTitle
            icon={<ProductDocumentsIcon fontSize="large" />}
            title="Tipos de Documentos"
            description="Administre os tipos de documentos das empresas."
          />
        </Grid>

        <Grid item xs={12}>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} justifyContent="space-between">
            <SelectEmpresaFilter
              empresas={empresas}
              id_empresas={empresas}
              defaultValue={selectedEmpresaId}
              onChangeValue={(value) => {
                setSelectedEmpresaId(value);
                setEmpresaIdSession(value);
                setPage(0);
              }}
            />
            {hasPermission(['admin', 'admin_tipoDocumento', 'create_tipoDocumento']) && (
              <Grid item xs={12} md={4}>
                <Typography sx={{ textAlign: { xs: 'center', md: 'right' } }}>
                  <Button variant="contained" startIcon={<Add />} onClick={() => setOpenForm(true)}>
                    Cadastrar Tipo de Documento
                  </Button>
                </Typography>
              </Grid>
            )}
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table aria-label="Tipo de Documento">
              <TableHead>
                <TableRow>
                  <TableCell>Título</TableCell>
                  <TableCell>Observação</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {listaTiposDocumentoIsLoading ? (
                  <SkeletonTableRow
                    numCells={3}
                    numRows={rowsPerPage < 20 ? rowsPerPage : 5}
                  />
                ) : tiposDocumento.length > 0 ? (
                  tiposDocumento.map((item) => (
                    <DocumentTypeRow
                      key={item.id}
                      data={item}
                      handleOpenEditForm={() => handleEdit(item)}
                      handleOpenDeleteDialog={() => handleOpenDeleteDialog(item)}
                    />
                  ))
                ) : (
                  <EmptyTableRow colSpan={3} />
                )}
              </TableBody>
            </Table>
            <CustomTablePagination
              numTotalItems={numTotalItems}
              rowsPerPage={rowsPerPage}
              page={page}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
            />
          </TableContainer>
        </Grid>
      </Grid>

      <DocumentTypeForm
        open={openForm}
        setOpen={(value) => {
          setOpenForm(value);
          if (!value) {
            setSelectedTipoDocumento(null);
          }
        }}
        documentType={selectedTipoDocumento}
        empresas={empresas}
        queryKey={queryKey}
      />

      <ConfirmDialog
        description="Tem certeza que deseja excluir este Tipo de Documento?"
        title="Excluir Tipo de Documento"
        goAction={() => handleDelete(selectedTipoDocumento.id)}
        handleClose={() => {
          setOpenDeleteDialog(false);
          setSelectedTipoDocumento(null);
        }}
        state={deleteOpenDialog}
      />
    </>
  );
}