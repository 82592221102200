import { TableCell, TableRow, IconButton, Tooltip } from '@mui/material';
import { hasPermission } from '../../../../../configs/functions';
import { ManualIcon } from '../../../../common/Icons';
import { RetiradaExtraStatusCell } from '../../reports/components/RetiradaExtraStatusCell';

export function RetiradaExtraRow({ data, handleOpenEditForm }) {
    const {
        pessoa,
        produto,
        produtoFilho,
        justificativaOcorrencia,
        status,
        responsavel,
        createdAt
    } = data;

    const produtoInfo = produtoFilho
        ? `${produtoFilho.nome} - ${produtoFilho.ca}`
        : `${produto?.nome || 'Não Informado'} - ${produto?.ca || ''}`;

    const canAnalyze = hasPermission(["admin", "admin_retiradaExtra", "updateStatus_retiradaExtra"]);

    return (
        <TableRow sx={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
            <TableCell sx={{ flex: 2, border: 0 }}>{pessoa?.nome || 'Sem nome'}</TableCell>
            <TableCell sx={{ flex: 2, border: 0, minWidth: 160 }}>{produtoInfo}</TableCell>
            <TableCell sx={{ flex: 3, border: 0 }}>{justificativaOcorrencia?.nome || 'Sem justificativa'}</TableCell>
            <TableCell sx={{ flex: 1, border: 0, minWidth: 115 }}>
                <RetiradaExtraStatusCell status={status} />
            </TableCell>
            <TableCell sx={{ flex: 2, border: 0 }}>{responsavel?.nome || 'N/A'}</TableCell>
            <TableCell sx={{ flex: 2, border: 0 }}>{createdAt}</TableCell>
            {canAnalyze && (
                <TableCell align="right" sx={{ flex: 1, border: 0 }}>
                    <Tooltip title="Analisar Retirada">
                        <IconButton
                            aria-label="Analisar Retirada"
                            onClick={() => handleOpenEditForm(data)}
                        >
                            <ManualIcon fontSize="large" />
                        </IconButton>
                    </Tooltip>
                </TableCell>
            )}
        </TableRow>
    )
}