import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Grid, Skeleton, Typography } from '@mui/material';
import { CompanyVariations } from './CompanyVariations';
import { listaTamanhosEVariacoes } from '../configs/functions';
import { extractErrorMessage } from '../../../../../utils/extract-error-message';

export function StepProductVariations({ empresasAssociadas, GHEs, register, getValues, setValue, errors, setError, clearErrors, handleExibirAlerta }) {
  const idContratos = empresasAssociadas.map((empresa) => empresa.idContrato);

  const queryKeyListProducts = ['listOfVariations'];
  const { data: productsData, isLoading, isPending, error: listOfVariationsError } = useQuery({
    queryKey: queryKeyListProducts,
    queryFn: () => listaTamanhosEVariacoes({ id_contratos: idContratos }),
  });

  const variations = productsData?.data?.data ?? [];
  const isLoadingList = isLoading || isPending;

  useEffect(() => {
    let errorMessage = "";

    if (listOfVariationsError) {
      errorMessage = extractErrorMessage(listOfVariationsError, 'Erro ao buscar variações');
    }

    errorMessage && handleExibirAlerta('Ops! Ocorreu um erro', errorMessage, 'error');
  }, [listOfVariationsError, handleExibirAlerta]);

  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h2" component={'h2'}>
          Cadastro Produto: {getValues('nome')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {!isLoadingList ?
          <CompanyVariations
            variations={variations}
            empresasAssociadas={empresasAssociadas}
            GHEs={GHEs}
            setValue={setValue}
            getValues={getValues}
            register={register}
            setError={setError}
            errors={errors}
            clearErrors={clearErrors}
          />
          : <Skeleton variant="rectangular" width="100%" height={200} />
        }
      </Grid>
    </Grid>
  );
};
