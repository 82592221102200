import React from 'react';
import ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import Theme from './configs/theme';
import { AppProvider } from './contexts/AppContext';
import { CommonItensProvider } from './contexts/CommonItensProvider';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { ptBR } from '@mui/x-date-pickers/locales';
import { queryClient } from './lib/react-query';
import { QueryClientProvider } from '@tanstack/react-query';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ModuleProvider } from './contexts/ModuleProvider';

// Definição das URLs
const apiUrl = process.env.REACT_APP_API || '';
const consultaCaUrl = process.env.REACT_APP_CONSULTA_CA_URL || '';
const consultaCepUrl = process.env.REACT_APP_CONSULTA_CEP_URL || '';
const s3BucketUrl = process.env.REACT_APP_S3_BUCKET_URL || '';
const privateApiUrl = 'https://private-api.safetowork.com.br/api/';
const consultaSempherUrl = 'https://consultaca.sempher.com.br/consulta.php';
const viacepUrl = 'https://viacep.com.br/';
const googleFontsUrl = 'https://fonts.gstatic.com';
const googleFontsStylesheet = 'https://fonts.googleapis.com';
const googleRecaptchaUrls = [
  'https://www.google.com',
  'https://www.gstatic.com',
  'https://www.recaptcha.net',
  'https://www.google.com/recaptcha/',
  'https://www.gstatic.com/recaptcha/',
  'https://www.google.com/recaptcha/api2/',
  'https://www.google.com/recaptcha/api2/clr',
  'https://www.google.com/recaptcha/api/siteverify',
  'https://www.google.com/recaptcha/enterprise/',
  'https://www.gstatic.com/recaptcha/releases/',
  'https://www.googleusercontent.com/'
];

// Política de Segurança de Conteúdo (CSP)
const csp = `
  default-src 'self';
  script-src 'self' 'unsafe-inline' 'unsafe-eval' ${googleRecaptchaUrls.join(' ')};
  style-src 'self' 'unsafe-inline' ${googleFontsStylesheet};
  img-src 'self' data: blob: ${s3BucketUrl} https://d13lq4ofpixls.cloudfront.net/ ${googleRecaptchaUrls.join(' ')};
  font-src 'self' data: ${googleFontsUrl};
  connect-src 'self' ${apiUrl} ${consultaCaUrl} ${consultaCepUrl} ${privateApiUrl} ${consultaSempherUrl} ${viacepUrl} ${googleRecaptchaUrls.join(' ')};
  frame-src 'self' ${googleRecaptchaUrls.join(' ')};
`;

// Inicialização do React
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Helmet>
        <meta httpEquiv="Content-Security-Policy" content={csp.trim()} />
        <title>STW - SAFE TO WORK | Sistema de Gestão de Segurança do Trabalho</title>
      </Helmet>
      <AppProvider>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={Theme}>
            <CssBaseline />
            <CommonItensProvider>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="pt-br"
                localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}
              >
                <QueryClientProvider client={queryClient}>
                  <ModuleProvider>
                    <App />
                  </ModuleProvider>
                </QueryClientProvider>
              </LocalizationProvider>
            </CommonItensProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </AppProvider>
    </HelmetProvider>
  </React.StrictMode>
);

// Monitoramento de performance
reportWebVitals();
