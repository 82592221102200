import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Helmet } from 'react-helmet-async';
import {
  Grid,
  Breadcrumbs,
  Typography,
  Button,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Table,
  IconButton,
  Stack,
  Collapse,
  Box
} from '@mui/material';
import { Add, Home as HomeIcon, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import PageTitle from '../../../common/PageTitle';
import LinkNavegacao from '../../../common/Link';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { BriefCaseIcon, EditIcon, TrashIcon } from '../../../common/Icons';
import ConfirmDialog from '../../../common/ConfirmDialog';
import { hasPermission } from '../../../../configs/functions';
import { EmptyTableRow } from '../../../common/EmptyTableRow';
import { SkeletonTableRow } from '../../../common/SkeletonTableRow';
import { CustomTablePagination } from '../../../common/CustomTablePagination';
import { deletaRole, listaRoles } from '../configs/functions';
import { AbilityForm } from './AbilityForm';
import { AbilitiesRole } from './AbilitiesRole';

const RoleRow = ({ data, handleOpenEditForm, handleOpenDeleteDialog }) => {
  const [openAbilities, setOpenAbilities] = useState(false);

  return (
    <>
      <TableRow hover>
        <TableCell align='center' sx={{ maxWidth: 20 }}>
          <IconButton
            aria-label="Habilidades"
            onClick={() => setOpenAbilities(!openAbilities)}
          >
            {openAbilities ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>{data.nome}</TableCell>
        <TableCell>{data.descricao}</TableCell>
        <TableCell align='right' sx={{ minWidth: 110 }}>
          {hasPermission(["admin_sempher"]) && (
            <IconButton aria-label="Editar" onClick={() => handleOpenEditForm(data)}>
              <EditIcon />
            </IconButton>
          )}
          {hasPermission(["admin_sempher"]) && (
            <IconButton aria-label="Excluir" onClick={() => handleOpenDeleteDialog(data)}>
              <TrashIcon />
            </IconButton>
          )}
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={openAbilities} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <AbilitiesRole
                abilities={data.abilities || []}
                handleOpenModalFilial={() => { }}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export function ListOfRoles() {
  const queryClient = useQueryClient();

  const { exibirAlerta } = useCommonItems();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [selectedRole, setSelectedRoles] = useState(null);
  const [openForm, setOpenForm] = useState(false);
  const [deleteOpenDialog, setOpenDeleteDialog] = useState(false);

  const queryKey = ['listaRoles', page, rowsPerPage];

  const { data: listaRolesData, isPending: listaRolesIsLoading } = useQuery({
    queryKey,
    queryFn: () => listaRoles({ limit: rowsPerPage, offset: page * rowsPerPage, })
  });

  const roles = listaRolesData?.data?.data ?? [];
  const numTotalItems = listaRolesData?.data?.numero_total ?? 0;

  const handleEdit = (item) => {
    setSelectedRoles(item);
    setOpenForm(true);
  };

  const handleOpenDeleteDialog = (item) => {
    setSelectedRoles(item);
    setOpenDeleteDialog(true);
  };

  const { mutateAsync: handleDelete } = useMutation({
    mutationFn: deletaRole,
    onSuccess: (response, id) => {
      const message = response.data.message ?? 'Função excluída com sucesso';
      exibirAlerta('Sucesso', message, 'success');

      const cached = queryClient.getQueryData(queryKey);
      const updatedData = {
        ...cached,
        data: {
          ...cached.data,
          data: cached.data.data.filter((item) => item.id !== id),
        },
      };
      queryClient.setQueryData(queryKey, updatedData);
    },
    onError: (error) => {
      const message = error.response?.data?.message ?? 'Erro ao executar operação';
      exibirAlerta('Erro', message, 'error');
    },
    onSettled: () => {
      setOpenDeleteDialog(false);
      setSelectedRoles(null);
    },
  });

  return (
    <>
      <Helmet title="Cargos | Admin " defer={false} />

      <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={3}>
        <Grid item xs={12}>
          <Breadcrumbs>
            <LinkNavegacao to='/'><HomeIcon fontSize='small' /></LinkNavegacao>
            <Typography variant='span'>Gerenciamento Admin</Typography>
            <LinkNavegacao to="/admin/organizacao">Organização</LinkNavegacao>
            <Typography variant="span">Cargos</Typography>
          </Breadcrumbs>

          <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} justifyContent="space-between" alignItems='end'>
            <PageTitle
              icon={<BriefCaseIcon fontSize="large" />}
              title="Cargos"
              description="Os Cargos são utilizadas para definir as permissões de acesso dos usuários."
            />

            <Grid item xs={12} md={4}>
              <Typography sx={{ textAlign: { xs: 'center', md: 'right' }, mb: 1 }}>
                <Button variant="contained" startIcon={<Add />} onClick={() => setOpenForm(true)}>
                  Cadastrar Função
                </Button>
              </Typography>
            </Grid>
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table aria-label="Cargos (Roles)">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Função (Role)</TableCell>
                  <TableCell>Descrição</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {listaRolesIsLoading ? (
                  <SkeletonTableRow
                    firstCellIsIcon
                    numCells={4}
                    numRows={rowsPerPage < 20 ? rowsPerPage : 5}
                  />
                ) : roles.length > 0 ? (
                  roles.map((item) => (
                    <RoleRow
                      key={item.id}
                      data={item}
                      handleOpenEditForm={() => handleEdit(item)}
                      handleOpenDeleteDialog={() => handleOpenDeleteDialog(item)}
                    />
                  ))
                ) : (
                  <EmptyTableRow colSpan={4} />
                )}
              </TableBody>
            </Table>

            <CustomTablePagination
              numTotalItems={numTotalItems}
              rowsPerPage={rowsPerPage}
              page={page}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
            />
          </TableContainer>
        </Grid>
      </Grid>

      <AbilityForm
        open={openForm}
        setOpen={(value) => {
          setOpenForm(value);
          if (!value) {
            setSelectedRoles(null);
          }
        }}
        documentType={selectedRole}
        queryKey={queryKey}
      />

      <ConfirmDialog
        description="Tem certeza que deseja excluir essa Função?"
        title="Excluir Função"
        goAction={() => handleDelete(selectedRole.id)}
        handleClose={() => {
          setOpenDeleteDialog(false);
          setSelectedRoles(null);
        }}
        state={deleteOpenDialog}
      />
    </>
  );
}