import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { Divider, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { getFormErrorMessage } from '../../../../../../configs/functions';

const fileTypes = [
  { value: 'csv', label: 'CSV' },
  { value: 'txt', label: 'TXT' },
  { value: 'xlsx', label: 'XLSX' },
  { value: 'xml', label: 'XML' },
];

// delimitador -> only for CSV and TXT
const delimiters = [
  { value: ',', label: 'Vírgula (",")' },
  { value: ';', label: 'Ponto e Vírgula (";")' },
  { value: '|', label: 'Pipe ("|")' },
];

// codificado -> only for TXT
const encodings = [
  { value: 'UTF-8', label: 'UTF-8' },
  { value: 'ISO-8859-1', label: 'ISO-8859-1' },
];

export function FileTypeConfigStep({ control, setValue, errors, clearErrors, getValues }) {
  const [selectedFileType, setSelectedFileType] = useState(!!getValues('tipo_arquivo') ? getValues('tipo_arquivo') : 'csv');

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={2}
    >
      <Grid item xs={12} md={4}>
        <Controller
          name="tipo_arquivo"
          control={control}
          render={({ field }) => (
            <TextField
              select
              margin='dense'
              label='Tipo de Arquivo'
              placeholder='Selecione o Tipo de Arquivo'
              fullWidth
              {...field}
              error={!!errors.tipo_arquivo}
              helperText={getFormErrorMessage(errors, 'tipo_arquivo')}
              variant="outlined"
              onChange={(e) => {
                field.onChange(e)

                clearErrors('tipo_arquivo')
                clearErrors('config_arquivo.delimitador')
                clearErrors('config_arquivo.codificador')

                const selectedFileType = e.target.value
                setSelectedFileType(selectedFileType)

                if (selectedFileType !== 'csv' && selectedFileType !== 'txt') {
                  setValue('config_arquivo.delimitador', '')
                  setValue('config_arquivo.codificador', '')
                }
              }}
            >
              {fileTypes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h6">
          Configurações
        </Typography>
        <Divider />
      </Grid>

      {(selectedFileType === 'csv' || selectedFileType === 'txt') ? (
        <>
          <Grid item xs={12} md={4}>
            <Controller
              name="config_arquivo.delimitador"
              control={control}
              render={({ field }) => (
                <TextField
                  select
                  margin="dense"
                  label="Delimitador"
                  placeholder="Selecione o Delimitador"
                  fullWidth
                  {...field}
                  error={!!errors.config_arquivo?.delimitador}
                  helperText={getFormErrorMessage(errors, 'config_arquivo.delimitador')}
                  variant="outlined"
                >
                  {delimiters.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>

          {selectedFileType === 'txt' && (
            <Grid item xs={12} md={4}>
              <Controller
                name="config_arquivo.codificador"
                control={control}
                defaultValue={encodings[0].value}
                render={({ field }) => (
                  <TextField
                    select
                    margin="dense"
                    label="Codificador"
                    placeholder="Selecione o Codificador"
                    defaultValue={field.value}
                    fullWidth
                    {...field}
                    error={!!errors.config_arquivo?.codificador}
                    helperText={getFormErrorMessage(errors, 'config_arquivo.codificador')}
                    variant="outlined"
                  >
                    {encodings.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
          )}
        </>
      ) : (
        <Grid item xs={12}>
          <Typography variant="body2" color="textSecondary" my={3}>
            Nenhum configuração adicional necessária.
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}

