import { Grid, TextField } from '@mui/material';
import { getFormErrorMessage } from '../../../../../../configs/functions';

export function IdentityStep({ control, register, errors }) {
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={2}
    >
      <Grid item xs={12}>
        <TextField
          InputLabelProps={{ shrink: true }}
          margin='dense'
          label='Nome'
          placeholder='Digite o nome da Configuração'
          name='nome'
          fullWidth
          {...register("nome", { required: true })}
          error={!!errors.nome}
          helperText={getFormErrorMessage(errors, 'nome')}
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          InputLabelProps={{ shrink: true }}
          margin='dense'
          label='Descrição'
          placeholder='Digite a Descrição'
          name='descricao'
          fullWidth
          {...register("descricao")}
          variant="outlined"
          multiline
          rows={3}
          error={!!errors.descricao}
          helperText={getFormErrorMessage(errors, 'descricao')}
        />
      </Grid>
    </Grid>
  )
}