import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Controller, useFieldArray } from 'react-hook-form';
import {
  Box,
  Grid,
  Typography,
  Stack,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Tooltip,
  IconButton,
  Button,
  TextField,
  MenuItem,
} from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import { HelpIcon } from '../../../../../common/Icons';
import { getCamposTabela } from '../../configs/functions';
import { getFormErrorMessage } from '../../../../../../configs/functions';
import DateRangePicker from '../../../../../common/DateRangePicker';
import dayjs from 'dayjs';

export function ExportDetailsStep({ control, register, setValue, getValues, errors, clearErrors }) {
  const [dateRange, setDateRange] = useState([null, null]);

  const { fields: configFixasFields, append: appendConfigFixa, remove: removeConfigFixa } = useFieldArray({
    control,
    name: 'config_fixas',
  });

  const { data: queryCamposTabela, isLoading, isPending } = useQuery({
    queryKey: ['getCamposTabela', 'retirada'],
    queryFn: async () => {
      const response = await getCamposTabela({ tipo_registro: 'retirada' })
      const campos = response?.data?.campos || {};
      const camposArray = Object.values(campos);
      return camposArray;
    },
    enabled: !!'retirada',
    refetchOnWindowFocus: false,
  });

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={2}
    >
      <Grid item xs={12}>
        <Stack direction={'row'} alignItems={'center'} gap={1}>
          <Typography variant="h6" color="textPrimary">
            Detalhes
          </Typography>
        </Stack>
      </Grid>

      <Grid item xs={12} md={12}>
        <Box sx={{ width: '100%', backgroundColor: '#fff', p: 2, borderRadius: 1 }}>
          <Grid container spacing={3}>
            <Grid container item xs={12} spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h6" color="textPrimary">
                  Configuração selecionada:
                </Typography>
                <Typography color="textSecondary">
                  {getValues('nome_tipo_exportacao')}
                </Typography>
              </Grid>
            </Grid>

            <Grid container item xs={12} spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h6" color="textPrimary">
                  Opções de Exportação:
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Controller
                  name="exibir_itens_exportados"
                  control={control}
                  rules={{ validate: (value) => value !== "" }}
                  defaultValue=''
                  render={({ field }) => (
                    <FormControl component="fieldset">
                      <Stack direction={'row'} alignItems={'center'} gap={1} my={0}>
                        <FormLabel component="legend">Exibir itens já Exportados?</FormLabel>
                        <Tooltip title="Selecione se deseja exibir itens que já foram exportados anteriormente para esta configuração de exportação. Esta opção controla a repetição dos dados nas exportações subsequentes.">
                          <IconButton><HelpIcon /></IconButton>
                        </Tooltip>
                      </Stack>

                      <RadioGroup
                        row
                        {...field}
                        value={field.value === true ? 'true' : field.value === false ? 'false' : ''}
                        onChange={(e) => {
                          field.onChange(e.target.value === 'true')
                          clearErrors('exibir_itens_exportados')
                        }}
                      >
                        <FormControlLabel value="false" control={<Radio />} label="Não" />
                        <FormControlLabel value="true" control={<Radio />} label="Sim" />
                      </RadioGroup>

                      {errors.exibir_itens_exportados && (
                        <Typography variant='caption' color="error">
                          Campo obrigatório
                        </Typography>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Controller
                  name="exibir_exportados_api"
                  control={control}
                  rules={{ validate: (value) => value !== "" }}
                  defaultValue=''
                  render={({ field }) => (
                    <FormControl component="fieldset">
                      <Stack direction={'row'} alignItems={'center'} gap={1} my={0}>
                        <FormLabel component="legend">Exibir itens já foram exportados via API?</FormLabel>
                        <Tooltip title="Selecione se deseja exibir os itens que já foram exportados anteriormente através da integração via API.">
                          <IconButton><HelpIcon /></IconButton>
                        </Tooltip>
                      </Stack>

                      <RadioGroup
                        row
                        {...field}
                        value={field.value === true ? 'true' : field.value === false ? 'false' : ''}
                        onChange={(e) => {
                          field.onChange(e.target.value === 'true')
                          clearErrors('exibir_exportados_api')
                        }}
                      >
                        <FormControlLabel value="false" control={<Radio />} label="Não" />
                        <FormControlLabel value="true" control={<Radio />} label="Sim" />
                      </RadioGroup>

                      {errors.exibir_exportados_api && (
                        <Typography variant='caption' color="error">
                          Campo obrigatório
                        </Typography>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
            </Grid>

            <Grid container item xs={12} spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h6">
                  Período de Exportação
                </Typography>
              </Grid>

              <Grid item xs={12} md={5.5}>
                <DateRangePicker
                  textFieldSize="medium"
                  value={dateRange}
                  onChange={(value) => {
                    setDateRange(value);
                    const [start, end] = value;
                    setValue('data_inicio', start ? dayjs(start).format('YYYY-MM-DD') : '');
                    setValue('data_fim', end ? dayjs(end).format('YYYY-MM-DD') : '');
                  }}
                  onReset={() => {
                    setDateRange([null, null]);
                    setValue('data_inicio', '');
                    setValue('data_fim', '');
                  }}
                  hasError={errors.data_inicio || errors.data_fim}
                />
              </Grid>
            </Grid>

            <Grid container item xs={12} spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h6">Filtros Adicionais</Typography>
              </Grid>

              {configFixasFields.map((item, index) => (
                <Grid container item xs={12} spacing={2} key={item.id}>
                  <Grid item xs={12} md={5.5}>
                    <Controller
                      name={`config_fixas.${index}.nome`}
                      control={control}
                      rules={{ required: !!getValues(`config_fixas.${index}.valor`) }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          select
                          fullWidth
                          sx={{ mt: 1 }}
                          label="Selecione um Campo"
                          onChange={(e) => {
                            setValue(`config_fixas.${index}.nome`, e.target.value);
                          }}
                          error={!!errors.config_fixas?.[index]?.nome}
                          helperText={getFormErrorMessage(errors, `config_fixas[${index}].nome`)}
                        >
                          {isLoading || isPending ? (
                            <MenuItem disabled>Carregando...</MenuItem>
                          ) :
                            queryCamposTabela.length > 0 ? (
                              queryCamposTabela.map((campo, idx) => (
                                <MenuItem key={idx} value={campo}>
                                  {campo}
                                </MenuItem>
                              ))
                            ) : <MenuItem disabled>Nenhum disponível</MenuItem>
                          }
                        </TextField>
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={5.5}>
                    <TextField
                      label="Valor"
                      placeholder="Digite o valor"
                      {...register(`config_fixas.${index}.valor`)}
                      margin="dense"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12} md={1} display="flex" alignItems="center">
                    <IconButton onClick={() => removeConfigFixa(index)}>
                      <Delete />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}

              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Add />}
                  onClick={() => appendConfigFixa({ nome: '', valor: '' })}
                >
                  Adicionar Filtro
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}