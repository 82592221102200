import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import {
  Collapse,
  Card,
  Grid,
  TextField,
  MenuItem,
} from '@mui/material';
import { TableFilterButtons } from '../../../../common/TableFilterButtons';
import DateRangePicker from '../../../../common/DateRangePicker';

const statusOptions = [
  { label: 'Solicitado', value: 'solicitado' },
  { label: 'Gerado', value: 'gerado' },
  { label: 'Erro', value: 'erro' },
];

export function ExportsTableFilter({ tiposExportacao, isLoadingCompanyData, isOpenFilters, isLoadingList }) {
  const [searchParams, setSearchParams] = useSearchParams()

  const status = searchParams.get('status');
  const id_tipo_exportacao = searchParams.get('id_tipo_exportacao');
  const data_inicio = searchParams.get('data_inicio');
  const data_fim = searchParams.get('data_fim');
  const perPage = searchParams.get('perPage');

  const [dateRange, setDateRange] = useState([
    data_inicio ? dayjs(data_inicio) : null,
    data_fim ? dayjs(data_fim) : null
  ]);

  const {
    reset,
    control,
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: {
      data_inicio: data_inicio ?? '',
      data_fim: data_fim ?? '',
      id_tipo_exportacao: id_tipo_exportacao ?? '',
      status: status ?? '',
    },
  });

  function handleSubmitFilter(data) {
    for (const key in data) {
      // remove campos vazios
      if (data[key] === '') {
        delete data[key];
      }
    }

    const queryFilters = {
      ...data,
      page: '1',
      perPage: perPage ?? '10'
    }

    // fill url params with filters
    setSearchParams(queryFilters);
  }

  useEffect(() => {
    reset({
      status: status ?? '',
      id_tipo_exportacao: id_tipo_exportacao ?? '',
      data_inicio: data_inicio ?? '',
      data_fim: data_fim ?? '',
    });
  }, [id_tipo_exportacao, data_inicio, data_fim, status, reset])

  return (
    <Grid item xs={12} component="form" onSubmit={handleSubmit(handleSubmitFilter)}>
      <Collapse in={isOpenFilters}>
        <Card variant="outlined" sx={{ padding: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4} lg={3}>
              <Controller
                name="id_tipo_exportacao"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    select
                    label="Configuração de Exportação"
                    fullWidth
                    size="small"
                    {...field}
                  >
                    {tiposExportacao.length > 0 && <MenuItem value="">Selecione...</MenuItem>}

                    {!isLoadingCompanyData ?
                      (tiposExportacao.length > 0 ?
                        (tiposExportacao.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.nome}
                          </MenuItem>
                        ))
                        ) : <MenuItem disabled>Nenhum disponível</MenuItem>
                      ) : <MenuItem disabled>Carregando...</MenuItem>
                    }
                  </TextField>
                )}
              />
            </Grid>

            <Grid item xs={12} md={4} lg={3}>
              <Controller
                name="status"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    select
                    label="Status"
                    fullWidth
                    size="small"
                    {...field}
                  >
                    {statusOptions.length > 0 && <MenuItem value="">Selecione...</MenuItem>}
                    {statusOptions.length > 0 ?
                      (statusOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))
                      ) : <MenuItem disabled>Nenhum disponível</MenuItem>
                    }
                  </TextField>
                )}
              />
            </Grid>

            <Grid item xs={12} md={4} lg={3}>
              <DateRangePicker
                value={dateRange}
                onChange={(value) => {
                  setDateRange(value);
                  const [start, end] = value;
                  setValue('data_inicio', start ? dayjs(start).format('YYYY-MM-DD') : '');
                  setValue('data_fim', end ? dayjs(end).format('YYYY-MM-DD') : '');
                }}
                onReset={() => {
                  setDateRange([null, null]);
                  setValue('data_inicio', '');
                  setValue('data_fim', '');
                }}
              />
            </Grid>

            <TableFilterButtons
              isLoading={isLoadingList}
              handleClearFilters={() => {
                setSearchParams((state) => {
                  state.delete('status')
                  state.delete('id_tipo_exportacao')
                  state.delete('data_inicio')
                  state.delete('data_fim')
                  state.set('page', '1')

                  return state
                })

                reset({
                  status: '',
                  id_tipo_exportacao: '',
                  data_inicio: '',
                  data_fim: '',
                });

                setDateRange([null, null]);
              }}
            />
          </Grid>
        </Card>
      </Collapse>
    </Grid>
  );
}
