import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { CheckCircleOutlineTwoTone, Close } from '@mui/icons-material';
import {
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Button,
  CircularProgress,
  Divider,
  Typography,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,
  TextField,
  MenuItem,
  Skeleton
} from '@mui/material';
import { atualizaStatusRetiradaExtra } from '../functions';
import { useCommonItems } from '../../../../../contexts/CommonItensProvider';
import { useAppContext } from '../../../../../contexts/AppContext';
import { grey } from '@mui/material/colors';
import { hasPermission } from '../../../../../configs/functions';
import ConfirmDialog from '../../../../common/ConfirmDialog';
import { extractErrorMessage } from '../../../../../utils/extract-error-message';
import { listaProdutos } from '../../products/configs/functions';

function SelectProductVariation({ isLoadingProducts, produtosFilho, idProdutoFilho, onChangeProdutoFilho }) {
  return (
    <>
      <Divider sx={{ width: '100%', mb: 2 }} />

      <Typography gutterBottom>
        Selecione o Tamanho/Variação do produto entregue:
      </Typography>

      {!isLoadingProducts ? (
        <TextField
          select
          name='id_produto_filho'
          fullWidth
          sx={{ mt: 1 }}
          label="Tamanho/Variação"
          defaultValue={idProdutoFilho}
          value={idProdutoFilho}
          onChange={(e) => onChangeProdutoFilho(e.target.value)}
          InputLabelProps={{ shrink: true }}
        >
          {(produtosFilho && produtosFilho.length > 0) ? (
            [
              ...produtosFilho.filter(option => option.status !== 0).map(option => (
                <MenuItem key={option.id} value={option.id}>
                  {option.variacao.tipo} - Cód: {option.cod_externo}
                </MenuItem>
              )),
              ...produtosFilho.filter(option => option.status === 0).map(option => (
                <MenuItem key={option.id} value={option.id} disabled>
                  {option.variacao.tipo} - Cód: {option.cod_externo} - (Inativo)
                </MenuItem>
              ))
            ]
          ) : (
            <MenuItem disabled>Nenhuma variação cadastrada.</MenuItem>
          )}
        </TextField>
      ) : (
        <Skeleton variant="rectangular" height={50} />
      )}
    </>
  );
}

export function ApproveRetiradaExtraModal({ open, setOpen, infoRetirada }) {
  const { dadosUsuario } = useAppContext();
  const { exibirAlerta } = useCommonItems();

  const [isLoading, setIsLoading] = useState(false);
  const [openDialogConfirmEntrega, setOpenDialogConfirmEntrega] = useState(false);
  const [openDialogAcceptAndConfirmEntrega, setOpenDialogAcceptAndConfirmEntrega] = useState(false);

  const [idProdutoFilho, setIdProdutoFilho] = useState(null);
  const [produtosFilhoVisible, setProdutosFilhoVisible] = useState(false);

  function handleClose() {
    setOpen(false);
  }

  async function handleUpdateStatus(status, idProdutoFilho = null) {
    setIsLoading(true);
    try {
      const idResponsavelEntrega = dadosUsuario?.pessoa?.id_pessoa ?? null;

      if (!idResponsavelEntrega) {
        throw new Error('Usuário não encontrado');
      }

      await atualizaStatusRetiradaExtra(infoRetirada.id, status, idResponsavelEntrega, idProdutoFilho);

      const messageStatus = {
        retirado: 'entregue',
        aceito: 'aceita',
        negado: 'recusada'
      }[status] || status;

      exibirAlerta('Sucesso', `Retirada ${messageStatus} com sucesso`, 'success');
      setOpenDialogConfirmEntrega(false);

      if (status !== 'aceito') {
        setOpenDialogAcceptAndConfirmEntrega(false);
        handleClose();
      }
    } catch (error) {
      console.log('Erro ao atualizar status:', error);
      const errorMessage = extractErrorMessage(error, 'Erro ao atualizar Retirada Extra');
      exibirAlerta('Ops!', errorMessage, 'error');
    } finally {
      setIsLoading(false);
    }
  }

  const queryKeyListProducts = ['listOfProductsFilho', infoRetirada];
  const { data: productsData, isLoading: isLoadingQuery, isPending, error: listOfProductsError } = useQuery({
    queryKey: queryKeyListProducts,
    queryFn: () => listaProdutos({ id_referencia: infoRetirada.produto.id }),
    enabled: !!infoRetirada && produtosFilhoVisible,
  });

  const produtosFilho = productsData?.data?.data ?? [];
  const isLoadingProducts = isLoadingQuery || isPending;

  const getProdutoInfo = () => {
    const produto = infoRetirada?.produtoFilho || infoRetirada?.produto;
    return produto
      ? `${produto.nome ?? "Não Informado(a)"} - ${produto.ca ?? "Não Informado(a)"}`
      : "Não Informado(a)";
  };

  useEffect(() => {
    if (infoRetirada && !isLoadingProducts) {
      const selectedIdProdutoFilho = infoRetirada?.produtoFilho?.id || null;
      setIdProdutoFilho(selectedIdProdutoFilho);
    } else {
      setIdProdutoFilho(null);
    }
  }, [infoRetirada, isLoadingProducts]);

  useEffect(() => {
    setProdutosFilhoVisible(infoRetirada?.status === 'aceito');
  }, [infoRetirada]);

  useEffect(() => {
    let errorMessage = "";

    if (listOfProductsError) {
      errorMessage = extractErrorMessage(listOfProductsError, 'Erro ao buscar produtos');
    }

    errorMessage && exibirAlerta('Ops! Ocorreu um erro', errorMessage, 'error');
  }, [listOfProductsError, exibirAlerta]);

  return (<>
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
      {infoRetirada && (
        <>
          <DialogTitle>Solicitação de Retirada</DialogTitle>

          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>

          <List sx={{ pt: 0, px: 3 }}>
            <Grid container spacing={2}>
              <Grid container item xs={12}>
                <ListItem disableGutters>
                  <ListItemText
                    primary={"Funcionário:"}
                    secondary={infoRetirada?.pessoa?.nome ?? "Não Informado(a)"}
                  />
                </ListItem>

                <Divider sx={{ width: '100%' }} />

                <ListItem disableGutters>
                  <ListItemText primary="Produto:" secondary={getProdutoInfo()} />
                </ListItem>

                <Divider sx={{ width: '100%' }} />

                <ListItem disableGutters>
                  <ListItemText
                    primary={"Justificativa:"}
                    secondary={infoRetirada?.justificativaOcorrencia?.nome ?? "Não Informado(a)"}
                  />
                </ListItem>

                <Divider sx={{ width: '100%' }} />

                <Grid item xs={12} md={6}>
                  <ListItem disableGutters>
                    <ListItemText
                      primary={"Equipamento:"}
                      secondary={infoRetirada?.equipamento?.nome ?? "Não Informado(a)"}
                    />
                  </ListItem>
                </Grid>

                <Divider sx={{ width: '100%', display: { sm: 'block', md: 'none' } }} />

                <Grid item xs={12} md={6}>
                  <ListItem disableGutters>
                    <ListItemText
                      primary="Responsável Entrega:"
                      secondary={infoRetirada?.responsavel?.nome ?? "Não Informado(a)"}
                    />
                  </ListItem>
                </Grid>

                <Divider sx={{ width: '100%' }} />

                <ListItem disableGutters>
                  <ListItemText
                    primary="Empresa:"
                    secondary={infoRetirada?.empresa?.nomeFantasia ?? "Não Informado(a)"}
                  />
                </ListItem>

                <Divider sx={{ width: '100%' }} />

                <Grid item xs={12} md={4}>
                  <ListItem disableGutters>
                    <ListItemText
                      primary="Grupo:"
                      secondary={infoRetirada?.grupo?.nome ?? "Não Informado(a)"}
                    />
                  </ListItem>
                </Grid>

                <Divider sx={{ width: '100%', display: { sm: 'block', md: 'none' } }} />

                <Grid item xs={12} md={4}>
                  <ListItem disableGutters>
                    <ListItemText
                      primary="Função:"
                      secondary={infoRetirada?.funcao?.nome ?? "Não Informado(a)"}
                    />
                  </ListItem>
                </Grid>

                <Divider sx={{ width: '100%', display: { sm: 'block', md: 'none' } }} />

                <Grid item xs={12} md={4}>
                  <ListItem disableGutters>
                    <ListItemText
                      primary="Ponto de Entrega:"
                      secondary={infoRetirada?.pontoEntrega ?? "Não Informado(a)"}
                    />
                  </ListItem>
                </Grid>

                <Divider sx={{ width: '100%' }} />

                <ListItem disableGutters>
                  <ListItemText
                    primary="Solicitação criada em:"
                    secondary={infoRetirada?.createdAt ?? "Não Informado(a)"}
                  />
                </ListItem>
              </Grid>
            </Grid>
          </List>

          <Grid container spacing={2} sx={{ px: 3, pb: 2 }}>
            <Grid item xs={12} sx={{ display: (produtosFilhoVisible && !openDialogAcceptAndConfirmEntrega) ? 'block' : 'none' }}>
              <SelectProductVariation
                isLoadingProducts={isLoadingProducts}
                produtosFilho={produtosFilho}
                idProdutoFilho={idProdutoFilho}
                onChangeProdutoFilho={(id) => setIdProdutoFilho(id)}
              />
            </Grid>

            <Grid container item xs={12} md={6}>
              <Grid item xs={12} md={6}>
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={handleClose}
                >
                  Voltar
                </Button>
              </Grid>
            </Grid>

            <Grid container item xs={12} md={6} spacing={2}>
              {infoRetirada?.status === 'aceito' && hasPermission(["admin", "admin_retiradaExtra"])
                ? (
                  <Grid item xs={12} md={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => setOpenDialogConfirmEntrega(true)}
                      disabled={isLoading || !idProdutoFilho}
                      startIcon={isLoading ? <CircularProgress size={16} sx={{ color: grey[400] }} /> : <CheckCircleOutlineTwoTone />}
                    >
                      Marcar como Entregue
                    </Button>
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={12} md={6}>
                      <Button
                        variant="contained"
                        color="error"
                        fullWidth
                        onClick={() => handleUpdateStatus('negado')}
                        disabled={isLoading || infoRetirada?.status !== 'pendente'}
                        startIcon={isLoading ? <CircularProgress size={16} sx={{ color: grey[400] }} /> : <Close />}
                      >
                        Recusar
                      </Button>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Button
                        variant="contained"
                        color="success"
                        fullWidth
                        onClick={() => setOpenDialogAcceptAndConfirmEntrega(true)}
                        disabled={isLoading || infoRetirada?.status !== 'pendente'}
                        startIcon={isLoading ? <CircularProgress size={16} sx={{ color: grey[400] }} /> : <CheckCircleOutlineTwoTone />}
                      >
                        Aceitar
                      </Button>
                    </Grid>
                  </>
                )}
            </Grid>
          </Grid>
        </>
      )}
    </Dialog>

    <ConfirmDialog
      title="Atenção!"
      description={
        <>
          <Typography gutterBottom>
            Ao marcar como entregue, você <strong>assume a responsabilidade pela entrega.</strong>
          </Typography>

          <Typography>
            Essa ação não poderá ser desfeita.
          </Typography>
        </>
      }
      confirmText="Entendi, marcar como entregue"
      buttonColor="primary"
      goAction={() => handleUpdateStatus('retirado', idProdutoFilho)}
      state={openDialogConfirmEntrega}
      handleClose={() => setOpenDialogConfirmEntrega(false)}
    />

    <Dialog
      open={openDialogAcceptAndConfirmEntrega}
      onClose={() => {
        setOpenDialogAcceptAndConfirmEntrega(false)
        handleClose();
      }}
    >
      <DialogTitle><strong>Esse Produto já foi entregue?</strong></DialogTitle>

      <DialogContent>
        <DialogContentText>
          <Typography gutterBottom>
            Caso o produto já tenha sido entregue, você pode <strong>marcar como entregue.</strong>
          </Typography>

          <Typography>
            Ao confirmar, você <strong>assume a responsabilidade pela entrega.</strong>
          </Typography>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={() => setProdutosFilhoVisible(prevState => !prevState)}>
          Sim, marcar como entregue
        </Button>

        <Button
          variant="contained"
          onClick={() => {
            setOpenDialogAcceptAndConfirmEntrega(false)
            handleClose()
            handleUpdateStatus('aceito')
          }}
        >
          Não, adicionar ao saldo da pessoa
        </Button>
      </DialogActions>

      <Box sx={{ display: produtosFilhoVisible ? 'block' : 'none' }}>
        <DialogContent>
          <SelectProductVariation
            isLoadingProducts={isLoadingProducts}
            produtosFilho={produtosFilho}
            idProdutoFilho={idProdutoFilho}
            onChangeProdutoFilho={(id) => setIdProdutoFilho(id)}
          />
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              setOpenDialogAcceptAndConfirmEntrega(false)
              handleClose()
              handleUpdateStatus('retirado', idProdutoFilho)
            }}
            disabled={isLoadingProducts || !idProdutoFilho}
          >
            Confirmar Entrega
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  </>);
}
