import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Box, Divider, Grid, List, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import SemFoto from '../../../../../assets/images/produto_sem_foto.svg';
import { Verified, Warning } from '@mui/icons-material';

function FormatarVariacoesSeparatedByEmpresa({ empresasSelecionadas, variacoes }) {
  if (!empresasSelecionadas || empresasSelecionadas.length === 0) return null;

  return (
    <>
      <Stack my={1} spacing={1} direction={'row'} alignItems={'center'}>
        <Verified color="success" fontSize='small' />
        <Typography variant="body1" fontWeight="bold">Variações Ativas:</Typography>
      </Stack>

      {variacoes.map((variacoesEmpresa, idEmpresaIndex) => {
        const empresa = empresasSelecionadas.find(({ id }) => id === idEmpresaIndex);

        const variacoesAtivas = variacoesEmpresa.filter(({ ativo }) => ativo);

        const meio = Math.ceil(variacoesAtivas.length / 2);
        const colunaEsquerda = variacoesAtivas.slice(0, meio);
        const colunaDireita = variacoesAtivas.slice(meio);

        const variacoesDeletadas = variacoesEmpresa.filter(({ rotulo, ativo }) => rotulo && !ativo);

        const meioDeletadas = Math.ceil(variacoesDeletadas.length / 2);
        const colunaEsquerdaDeletadas = variacoesDeletadas.slice(0, meioDeletadas);
        const colunaDireitaDeletadas = variacoesDeletadas.slice(meioDeletadas);

        return (
          <Grid container key={idEmpresaIndex}>
            <Grid item xs={12}>
              <Typography variant="body2">
                • Empresa: {empresa?.nome || 'Não Informado(a)'}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <List>
                {colunaEsquerda.map(({ rotulo, cod_externo }, index) => (
                  <ListItem key={index} disablePadding>
                    <ListItemText primary={<Typography variant="body1" fontWeight="bold">{rotulo}</Typography>} secondary={cod_externo} />
                  </ListItem>
                ))}
              </List>
            </Grid>

            <Grid item xs={6}>
              <List>
                {colunaDireita.map(({ rotulo, cod_externo }, index) => (
                  <ListItem key={index} disablePadding>
                    <ListItemText primary={<Typography variant="body1" fontWeight="bold">{rotulo}</Typography>} secondary={cod_externo} />
                  </ListItem>
                ))}
              </List>
            </Grid>

            {variacoesDeletadas.length > 0 && (
              <>
                <Stack spacing={1} direction={'row'} alignItems={'center'}>
                  <Warning color="warning" fontSize='small' />
                  <Typography variant="body1" fontWeight="bold">Variações Inativas:</Typography>
                </Stack>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <List>
                      {colunaEsquerdaDeletadas.map(({ rotulo, cod_externo }, index) => (
                        <ListItem key={index} disablePadding>
                          <ListItemText primary={<Typography variant="body1" fontWeight="bold">{rotulo}</Typography>} secondary={cod_externo} />
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                  <Grid item xs={6}>
                    <List>
                      {colunaDireitaDeletadas.map(({ rotulo, cod_externo }, index) => (
                        <ListItem key={index} disablePadding>
                          <ListItemText primary={<Typography variant="body1" fontWeight="bold">{rotulo}</Typography>} secondary={cod_externo} />
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                </Grid>
              </>
            )}

            <Grid item xs={12} mb={1.5}>
              <Divider />
            </Grid>
          </Grid>
        );
      })}
    </>
  );
}

const SelectedCompaniesList = ({ empresasSelecionadas, GHEs, gruposSelecionadosCriarPermissao, getValues }) => {
  return (
    <List>
      {empresasSelecionadas.map((empresa, index) => {
        const GHEsEmpresa = GHEs.filter((ghe) => ghe.id_empresa === empresa.id);
        return (
          <>
            <ListItem key={index} disablePadding>
              <ListItemText
                primary={empresa.nome}
                secondary={
                  <>
                    <Typography variant="body2" color="textSecondary" gutterBottom>
                      Vida Útil do EPI: {getValues(`vida_util.${empresa.id}`)} dias
                    </Typography>

                    {getValues('existsGheWithThisCategory') === true && GHEsEmpresa.length > 0 && (
                      <>
                        <Typography variant="body2" color="textPrimary" gutterBottom>
                          Grupos de Permissão Relacionados: {' '}
                          {GHEsEmpresa.map((ghe, index) =>
                            gruposSelecionadosCriarPermissao[ghe.id].status && (
                              <Typography component={'span'} key={ghe.id} disablePadding variant="body2" color="textSecondary">
                                {ghe.nome}{index < GHEsEmpresa.length - 1 ? ', ' : ''}
                              </Typography>
                            )
                          )}
                        </Typography>
                      </>
                    )}
                  </>
                }
              />
            </ListItem>
          </>
        )
      })}
    </List>
  );
};

export function StepProductConfirm({ empresas, empresasAssociadas, GHEs, getValues, isEditAction, unidadesDeMedida }) {
  const [selectedImage, setSelectedImage] = useState(null);

  const { nome, descricao, ca, validade_ca, indicacao, id_categorias, id_unidadeMedida, variacoes, precaucao, observacao, productImage, gruposSelecionadosCriarPermissao } = getValues();

  const [empresasSelecionadas, setEmpresasSelecionadas] = useState([]);

  useEffect(() => {
    const idsEmpresasSelecionadas = getValues('id_empresas');
    const novasEmpresasSelecionadas = [];

    if (idsEmpresasSelecionadas) {
      Object.entries(idsEmpresasSelecionadas).forEach(([chave, item]) => {
        if (item) {
          const empresa = empresasAssociadas.find(element => element.id === Number(chave));
          if (empresa) {
            novasEmpresasSelecionadas.push({
              id: empresa.id,
              nome: empresa.nomeFantasia,
            });
          }
        }
      });
    }

    setEmpresasSelecionadas(novasEmpresasSelecionadas);
  }, [getValues, empresasAssociadas]);

  // const formatarVariacoes = () => {
  //   const variacoesAtivas = variacoes.filter(({ ativo }) => ativo);

  //   const meio = Math.ceil(variacoesAtivas.length / 2);
  //   const colunaEsquerda = variacoesAtivas.slice(0, meio);
  //   const colunaDireita = variacoesAtivas.slice(meio);

  //   const variacoesDeletadas = variacoes.filter(({ rotulo, ativo }) => rotulo && !ativo);

  //   const meioDeletadas = Math.ceil(variacoesDeletadas.length / 2);
  //   const colunaEsquerdaDeletadas = variacoesDeletadas.slice(0, meioDeletadas);
  //   const colunaDireitaDeletadas = variacoesDeletadas.slice(meioDeletadas);

  //   return (
  //     <>
  //       <Stack mt={1} spacing={1} direction={'row'} alignItems={'center'}>
  //         <Verified color="success" fontSize='small' />
  //         <Typography variant="body1" fontWeight="bold">Variações Ativas:</Typography>
  //       </Stack>

  //       <Grid container spacing={2}>
  //         <Grid item xs={6}>
  //           <List>
  //             {colunaEsquerda.map(({ rotulo, cod_externo }, index) => (
  //               <ListItem key={index} disablePadding>
  //                 <ListItemText primary={<Typography variant="body1" fontWeight="bold">{rotulo}</Typography>} secondary={cod_externo} />
  //               </ListItem>
  //             ))}
  //           </List>
  //         </Grid>
  //         <Grid item xs={6}>
  //           <List>
  //             {colunaDireita.map(({ rotulo, cod_externo }, index) => (
  //               <ListItem key={index} disablePadding>
  //                 <ListItemText primary={<Typography variant="body1" fontWeight="bold">{rotulo}</Typography>} secondary={cod_externo} />
  //               </ListItem>
  //             ))}
  //           </List>
  //         </Grid>
  //       </Grid>

  //       {variacoesDeletadas.length > 0 && (
  //         <>
  //           <Stack spacing={1} direction={'row'} alignItems={'center'}>
  //             <Warning color="warning" fontSize='small' />
  //             <Typography variant="body1" fontWeight="bold">Variações Inativas:</Typography>
  //           </Stack>

  //           <Grid container spacing={2}>
  //             <Grid item xs={6}>
  //               <List>
  //                 {colunaEsquerdaDeletadas.map(({ rotulo, cod_externo }, index) => (
  //                   <ListItem key={index} disablePadding>
  //                     <ListItemText primary={<Typography variant="body1" fontWeight="bold">{rotulo}</Typography>} secondary={cod_externo} />
  //                   </ListItem>
  //                 ))}
  //               </List>
  //             </Grid>
  //             <Grid item xs={6}>
  //               <List>
  //                 {colunaDireitaDeletadas.map(({ rotulo, cod_externo }, index) => (
  //                   <ListItem key={index} disablePadding>
  //                     <ListItemText primary={<Typography variant="body1" fontWeight="bold">{rotulo}</Typography>} secondary={cod_externo} />
  //                   </ListItem>
  //                 ))}
  //               </List>
  //             </Grid>
  //           </Grid>
  //         </>
  //       )}
  //     </>
  //   );
  // };

  const formatarValidadeCA = (validadeDate) => {
    return dayjs(validadeDate).format('DD/MM/YYYY');
  };

  const formatarUnidadeDeMedida = () => {
    const unidade = unidadesDeMedida.find(item => item.id === id_unidadeMedida);
    return unidade ? unidade.nome : 'Não Informado(a)';
  };

  useEffect(() => {
    // Se o valor da imagem estiver presente no registro, define-a como a imagem selecionada
    const storedImage = getValues('productImage');
    if (storedImage) {
      if (typeof storedImage === 'string') {
        setSelectedImage(storedImage);
      } else if (storedImage instanceof File) {
        setSelectedImage(URL.createObjectURL(storedImage));
      }
    }
  }, [getValues, productImage]);

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={12} md={9}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h3">Resumo do Formulário</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h2" component={'h2'}>{nome ? nome : ''}</Typography> {/* Verifica se nome está definido */}
          </Grid>
          <Grid item>
            <Typography variant="body1"><strong>CA:</strong> {ca ? ca : 'Não Informado(a)'}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1"><strong>Validade CA:</strong>{' '}
              {validade_ca ? formatarValidadeCA(validade_ca) : 'Não Informado(a)'}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1"><strong>Descrição:</strong> {descricao}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1"><strong>Indicação:</strong> {indicacao}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1"><strong>Precaução:</strong> {precaucao}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1"><strong>Observação:</strong> {observacao}</Typography>
          </Grid>
          <Grid item>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography variant="body1"><strong>Categoria:</strong> {id_categorias}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body1"><strong>Unidade de Medida:</strong> {formatarUnidadeDeMedida()}</Typography>
              </Grid>
            </Grid>
          </Grid>
          {!isEditAction ? (
            <>
              <Grid item>
                <Typography variant="body1">
                  <strong>Empresa(s) Selecionadas</strong>
                  <SelectedCompaniesList
                    getValues={getValues}
                    empresasSelecionadas={empresasSelecionadas}
                    GHEs={GHEs}
                    gruposSelecionadosCriarPermissao={gruposSelecionadosCriarPermissao}
                  />
                </Typography>
              </Grid>
            </>
          ) : (
            <Grid item>
              <Typography variant="body1"><strong>Empresa Relacionada a este Produto:</strong>
                <br />
                {empresas.map((empresa) => {
                  if (empresa.id === getValues('id_empresa')) {
                    return (
                      <div key={empresa.id}>
                        <Typography variant="body1" mt={1}>{empresa.nomeFantasia}</Typography>
                        <Typography variant="body1" color='textSecondary'>
                          {"Vida Útil do EPI: " + getValues('vida_util') + " dias"}
                        </Typography>
                      </div>
                    );
                  }

                  return null;
                })}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} md={3} direction="column" spacing={2}>
        <Grid item>
          <Typography variant="body1"><strong>Imagem:</strong></Typography>
          <Box>
            <Box
              component='img'
              src={selectedImage || SemFoto}
              alt="Imagem do Produto"
              onError={(e) => e.target.src = SemFoto}
              style={{
                width: '85%',
                height: "100%",
                borderRadius: 4,
              }}
            />
          </Box>
        </Grid>
        <Grid item>
          <FormatarVariacoesSeparatedByEmpresa
            empresasSelecionadas={empresasSelecionadas}
            variacoes={variacoes}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}