import { useState, useEffect, useRef } from 'react';
import { useForm } from "react-hook-form";
import { useQueryClient } from '@tanstack/react-query';
import {
    Box,
    FormControl,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Stepper,
    Step,
    StepButton,
    StepLabel,
    useMediaQuery,
} from '@mui/material';
import { useCommonItems } from '../../../../../../contexts/CommonItensProvider';
import { fillInputErrors } from '../../../../../../configs/functions';
import { cadastraExportacao } from '../../configs/functions';
import { useAppContext } from '../../../../../../contexts/AppContext';
import { validateRequiredFields } from '../../../../../../utils/validate-required-fields';
import { CommonDialogActionsStepsForm } from '../../../../../common/CommonDialogActionsStepsForm';
import { SelectExportTypeStep } from './SelectExportTypeStep';
import { ExportDetailsStep } from './ExportDetailsStep';
import { PasswordConfirmationDialog } from '../../../../../common/PasswordConfirmationDialog';

const steps = ['Configuração', 'Detalhes'];

export function ExportForm({ tiposExportacao, isLoadingTiposExportacao, open, setOpen, queryKey }) {
    const queryClient = useQueryClient();
    const { dadosUsuario } = useAppContext();
    const { exibirDialog, handleCloseDialog, exibirAlerta } = useCommonItems();

    const passwordDialogRef = useRef();

    const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));

    const [isLoading, setIsLoading] = useState(false);
    const [activeStep, setActiveStep] = useState(0);

    const {
        register,
        handleSubmit,
        getValues,
        setValue,
        setError,
        clearErrors,
        reset,
        control,
        formState: { errors },
    } = useForm();

    const handleCreateExport = async (data) => {
        const isConfirmed = await passwordDialogRef.current.openPasswordConfirmation();
        if (!isConfirmed) {
            exibirAlerta('Ops!', 'Senha inválida.', 'error');
            return;
        }

        try {
            setIsLoading(true);
            exibirDialog('Salvando. Aguarde...');

            const loggedUserId = dadosUsuario.pessoa.id_pessoa;
            if (!loggedUserId) {
                exibirAlerta('Erro', 'Ocorreu um erro buscar informações do usuário.', 'error');
                return;
            }
            data.id_pessoa = loggedUserId;

            if ((data.config_fixas.length === 1 && !data.config_fixas[0].nome) && (!data.data_inicio && !data.data_fim)) {
                delete data.config_fixas;
            } else {
                // filtros (config_fixas)
                const configFixas = data.config_fixas.reduce((acc, item) => {
                    if (!item.nome) return acc;

                    acc[item.nome] = item.valor;
                    return acc;
                }, {});

                configFixas.data_inicio = data.data_inicio;
                configFixas.data_fim = data.data_fim;
                data.config_fixas = configFixas;

                delete data.data_inicio;
                delete data.data_fim;
            }

            console.log('handleCreateExport ~ data:', data);

            const res = await cadastraExportacao(data);
            queryKey && queryClient.invalidateQueries({ queryKey });
            exibirAlerta('Sucesso', res.data.message, 'success');
            setOpen(false);
        } catch (error) {
            console.error(error);
            let errorMessage = "Erro ao gerar Exportação.";
            if (error.response) {
                errorMessage = error.response.data.message
                fillInputErrors(error, setError);
            }
            exibirAlerta('Ops!', errorMessage, 'error');
        } finally {
            setIsLoading(false);
            handleCloseDialog();
        }
    };

    const handleNext = async (event) => {
        event.preventDefault();
        switch (activeStep) {
            case 0:
                if (await validateRequiredFields(getValues, setError, clearErrors, ['id_tipo_exportacao'])) {
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }
                break;
            case 1:
                if (await validateRequiredFields(getValues, setError, clearErrors, ['exibir_itens_exportados', 'exibir_exportados_api'])) {
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }
                break;
            default:
                break;
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (pos) => {
        if (pos < activeStep) {
            setActiveStep(pos)
        }
    }

    useEffect(() => {
        setActiveStep(0);

        if (open) {
            const defaultValues = {
                id_pessoa: "",
                id_tipo_exportacao: "",
                exibir_itens_exportados: false,
                exibir_exportados_api: true,
                config_fixas: [{ nome: '', valor: '' }],
            };

            reset(defaultValues);
        } else {
            reset();
        }
    }, [open, reset]);

    return (
        <>
            <Dialog open={open} fullWidth maxWidth={"md"}>
                <form onSubmit={handleSubmit(handleCreateExport)}>
                    <DialogTitle>Exportação de Dados</DialogTitle>

                    <DialogContent>
                        <DialogContentText sx={{ mb: 2 }}>
                            Preencha corretamente os dados abaixo para realizar a Exportação.
                        </DialogContentText>

                        <Stepper activeStep={activeStep} sx={{ px: { xs: 2, sm: 10 }, py: 2, overflowX: 'scroll' }} orientation={isMobile ? 'vertical' : 'horizontal'}>
                            {steps.map((label, index) => (
                                <Step key={label}>
                                    <StepButton color="inherit" onClick={() => handleStep(index)}>
                                        <StepLabel>{label}</StepLabel>
                                    </StepButton>
                                </Step>
                            ))}
                        </Stepper>

                        <Box sx={{ p: 2, backgroundColor: "#fafafa" }} >
                            <FormControl variant="standard" fullWidth>
                                {activeStep === 0 && (
                                    <SelectExportTypeStep
                                        register={register}
                                        errors={errors}
                                        control={control}
                                        setValue={setValue}
                                        getValues={getValues}
                                        clearErrors={clearErrors}
                                        handleExibirAlerta={exibirAlerta}
                                        tiposExportacao={tiposExportacao}
                                        isLoadingTiposExportacao={isLoadingTiposExportacao}
                                    />
                                )}
                                {activeStep === 1 && (
                                    <ExportDetailsStep
                                        errors={errors}
                                        control={control}
                                        register={register}
                                        setValue={setValue}
                                        getValues={getValues}
                                        clearErrors={clearErrors}
                                    />
                                )}
                            </FormControl>
                        </Box>
                    </DialogContent>

                    <CommonDialogActionsStepsForm
                        steps={steps}
                        activeStep={activeStep}
                        handleBack={handleBack}
                        handleNext={handleNext}
                        errors={errors}
                        isLoading={isLoading}
                        setOpen={setOpen}
                    />
                </form>
            </Dialog>

            <PasswordConfirmationDialog dialogRef={passwordDialogRef} />
        </>
    );
}