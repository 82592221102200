import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
    Breadcrumbs,
    Grid,
    Typography,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableHead,
    Paper,
    Button,
    IconButton,
    Tooltip,
    Box,
    Stack,
} from '@mui/material';
import { Add, Home as HomeIcon } from '@mui/icons-material';
import PageTitle from '../../../common/PageTitle';
import LinkNavegacao from '../../../common/Link';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { checkPermissionsAndRedirect, hasPermission } from '../../../../configs/functions';
import { ToolsIcon, TrashIcon } from '../../../common/Icons';
import { EmptyTableRow } from '../../../common/EmptyTableRow';
import { deletaTipoExportacao, listaTiposExportacao } from './configs/functions';
import { CustomTablePagination } from '../../../common/CustomTablePagination';
import { SkeletonTableRow } from '../../../common/SkeletonTableRow';
import { ExportTypeForm } from './components/ExportTypeForm/index';
import ConfirmDialog from '../../../common/ConfirmDialog';
import { extractErrorMessage } from '../../../../utils/extract-error-message';
import { formatKey, renderValue } from '../../epis/alerts/utils/formatDetailsAlert';

const ExportTypeRow = ({ item, handleOpenDeleteDialog }) => {
    const camposArray = Object.keys(JSON.parse(item.campos));

    return (
        <>
            <TableRow hover>
                <TableCell>{item.nome}</TableCell>
                <TableCell>{item.descricao}</TableCell>
                <TableCell>
                    <Typography variant='body2' fontWeight={700} color='primary' sx={{ textTransform: 'uppercase' }}>
                        {item.tipoArquivo}
                    </Typography>
                </TableCell>

                <TableCell>
                    <Stack spacing={1}>
                        {item.configArquivo ?
                            Object.entries(JSON.parse(item.configArquivo)).map(([key, value], index) => (
                                <Box key={`${key}-${index}`}>
                                    <Typography variant='body2'>
                                        <strong>{formatKey(key)}:</strong> {renderValue(value)}
                                    </Typography>
                                </Box>
                            ))
                            : <Typography variant='body2'>N/A</Typography>
                        }
                    </Stack>
                </TableCell>

                <TableCell>
                    {camposArray.length <= 3 ? (
                        camposArray.map((email, index) => (
                            <Typography key={index} variant="subtitle2">
                                {email}
                            </Typography>
                        ))
                    ) : (
                        <Tooltip
                            title={
                                <>
                                    {camposArray.map((email, index) => (
                                        <Typography key={index} variant="body2">{email}</Typography>
                                    ))}
                                </>
                            }
                            placement="bottom"
                        >
                            <Box sx={{ cursor: 'pointer' }}>
                                {camposArray.slice(0, 3).map((email, index) => (
                                    <Typography key={index} variant='subtitle2'>{email}</Typography>
                                ))}
                                <Typography variant='subtitle2'>+{camposArray.length - 3}</Typography>
                            </Box>
                        </Tooltip>
                    )}
                </TableCell>
                <TableCell>{item.tipoRegistro}</TableCell>
                <TableCell sx={{ minWidth: 110 }} align='right'>
                    <IconButton
                        aria-label="Excluir"
                        onClick={() => handleOpenDeleteDialog()}
                        disabled={!item.status}
                        sx={{ filter: !item.status ? "grayscale(1) brightness(1.4)" : "" }}
                    >
                        <TrashIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
        </>
    );
};

export function ListOfExportsTypes() {
    const queryClient = useQueryClient();
    const { exibirAlerta } = useCommonItems();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);

    const [openExportForm, setOpenExportForm] = useState(false);
    const [selectedExportType, setSelectedExportType] = useState(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const queryKey = ['listaTiposExportacao', page, rowsPerPage];
    const { data: queryModulosData, isLoading, isPending, error: listaTiposExportacaoError } = useQuery({
        queryKey: queryKey,
        queryFn: () => listaTiposExportacao(),
        refetchOnWindowFocus: false,
    });

    const exportacoes = queryModulosData?.data?.data || [];
    const isLoadingExportacoes = isLoading || isPending;
    const numTotalItems = queryModulosData?.data?.numero_total ?? 0;

    const handleOpenDeleteDialog = (item) => {
        setSelectedExportType(item);
        setOpenDeleteDialog(true);
    };

    const { mutateAsync: handleDelete } = useMutation({
        mutationFn: deletaTipoExportacao,
        onSuccess: (response, id) => {
            const message = response.data.message ?? 'Operação realizada com sucesso';
            exibirAlerta('Sucesso', message, 'success');
            queryClient.invalidateQueries({ queryKey });
        },
        onError: (error) => {
            const message = error.response?.data?.message ?? 'Erro ao executar operação';
            exibirAlerta('Erro', message, 'error');
        },
        onSettled: () => {
            setOpenDeleteDialog(false);
            setSelectedExportType(null);
        },
    });

    useEffect(() => {
        const requiredPermissionsView = ['admin', 'admin_exportacao', 'list_exportacao'];
        checkPermissionsAndRedirect(requiredPermissionsView);
    }, []);

    useEffect(() => {
        let errorMessage = "";

        if (listaTiposExportacaoError) {
            errorMessage = extractErrorMessage(listaTiposExportacaoError, 'Erro ao carregar as configurações de exportação');
        }

        errorMessage && exibirAlerta('Ops! Ocorreu um erro', errorMessage, 'error');
    }, [exibirAlerta, listaTiposExportacaoError]);

    return (
        <>
            <Helmet title="Configurações de Exportação" defer={false} />

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="end"
                spacing={3}
            >
                <Grid item xs={12} sm={8}>
                    <Breadcrumbs>
                        <LinkNavegacao to='/'><HomeIcon fontSize='small' /></LinkNavegacao>
                        <Typography variant='span'>Gerenciamento</Typography>
                        <LinkNavegacao to='/gerenciamento/configuracoes'>Configurações</LinkNavegacao>
                        <Typography variant='span'>Configurações de Exportação</Typography>
                    </Breadcrumbs>

                    <PageTitle
                        icon={<ToolsIcon fontSize='large' color='primary' />}
                        title='Configurações de Exportação'
                        description='Configure o formato de exportação dos dados de sua preferência.'
                    />
                </Grid>

                <Grid item xs={12} sm={4}>
                    <Typography sx={{ textAlign: { xs: 'center', md: 'right' } }}>
                        <Button
                            variant="contained"
                            startIcon={<Add />}
                            onClick={() => setOpenExportForm(true)}
                            disabled={!hasPermission(["admin", "admin_exportacao", "create_exportacao"])}
                        >
                            Cadastrar Config. Exportação
                        </Button>
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table aria-label="Configurações de Exportação">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nome</TableCell>
                                    <TableCell>Descrição</TableCell>
                                    <TableCell>Tipo de Arquivo</TableCell>
                                    <TableCell>Config. Arquivo</TableCell>
                                    <TableCell>Campos</TableCell>
                                    <TableCell>Tipo de Registro</TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {isLoadingExportacoes ?
                                    <SkeletonTableRow
                                        numCells={7}
                                        numActions={1}
                                        numRows={rowsPerPage < 20 ? rowsPerPage : 5}
                                    />
                                    : exportacoes && exportacoes.length > 0 ?
                                        exportacoes?.map((item) => (
                                            <ExportTypeRow
                                                key={item.id}
                                                item={item}
                                                handleOpenDeleteDialog={() => handleOpenDeleteDialog(item)}
                                            />
                                        ))
                                        : <EmptyTableRow colSpan={7} />
                                }
                            </TableBody>
                        </Table>

                        <CustomTablePagination
                            numTotalItems={numTotalItems}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            setPage={setPage}
                            setRowsPerPage={setRowsPerPage}
                        />
                    </TableContainer>
                </Grid>
            </Grid>

            <ExportTypeForm
                open={openExportForm}
                setOpen={setOpenExportForm}
                queryKey={queryKey}
                selectedExportType={selectedExportType}
            />

            <ConfirmDialog
                state={openDeleteDialog}
                title="Excluir Tipo de Documento"
                description="Tem certeza que deseja excluir este Tipo de Documento?"
                goAction={() => handleDelete(selectedExportType.id)}
                handleClose={() => {
                    setOpenDeleteDialog(false);
                    setSelectedExportType(null);
                }}
            />
        </>
    );
}