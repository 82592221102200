import { useState, useEffect } from 'react';
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    FormHelperText,
    CircularProgress,
    Typography,
    Stack,
    CardContent,
    Card,
    Collapse,
    Divider,
    Grid,
    TextField,
    IconButton
} from '@mui/material';
import { Category, ExpandLess, ExpandMore } from '@mui/icons-material';

function VariationItem({ data, empresa, register, setValue, getValues, errors, clearErrors, actionsAllowed }) {
    const [ativo, setAtivo] = useState(getValues(`variacoes.${empresa.id}.${data.id}.ativo`) || false);
    const [codExternoValue, setCodExternoValue] = useState(getValues(`variacoes.${empresa.id}.${data.id}.cod_externo`) || '');

    const alreadyExists = !!getValues(`variacoes.${empresa.id}.${data.id}.id_variacao`);
    const hasError = !!errors.variacoes?.[empresa.id]?.[data.id]?.cod_externo;

    const handleCheckboxChange = (e) => {
        if (!actionsAllowed) return;

        const isChecked = e.target.checked;
        setAtivo(isChecked);

        setValue(`variacoes.${empresa.id}.${data.id}.ativo`, isChecked);
        setValue(`variacoes.${empresa.id}.${data.id}.is_dirty`, true);

        if (isChecked && !alreadyExists) {
            setValue(`variacoes.${empresa.id}.${data.id}.is_new`, true);
            setValue(`variacoes.${empresa.id}.${data.id}.rotulo`, data.tipo);
            setValue(`variacoes.${empresa.id}.${data.id}.id_variacao`, data.id);
            setValue(`variacoes.${empresa.id}.${data.id}.id_empresa`, empresa.id);
        }

        if (!isChecked) {
            clearErrors(`variacoes.${empresa.id}.${data.id}.cod_externo`);

            const isNew = getValues(`variacoes.${empresa.id}.${data.id}.is_new`) || false;
            if (isNew) {
                setValue(`variacoes.${empresa.id}.${data.id}.cod_externo`, '');
                setCodExternoValue('');
                setValue(`variacoes.${empresa.id}.${data.id}.rotulo`, '');
                setValue(`variacoes.${empresa.id}.${data.id}.is_new`, false);
                setValue(`variacoes.${empresa.id}.${data.id}.id_variacao`, '');
                setValue(`variacoes.${empresa.id}.${data.id}.id_empresa`, '');
            }
        }
    };

    return (
        <>
            <FormControlLabel
                control={
                    <Checkbox
                        {...register(`variacoes.${empresa.id}.${data.id}.ativo`)}
                        checked={ativo}
                        onChange={handleCheckboxChange}
                        sx={{ cursor: actionsAllowed ? 'pointer' : 'not-allowed' }}
                        inputProps={{ readOnly: !actionsAllowed }}
                    />
                }
                label={data.tipo.trim()}
            />
            <TextField
                label="Código"
                variant="outlined"
                value={codExternoValue}
                {...register(`variacoes.${empresa.id}.${data.id}.cod_externo`)}
                size="small"
                disabled={!ativo}
                InputProps={{ readOnly: !actionsAllowed }}
                onChange={(e) => {
                    const value = e.target.value.toUpperCase();
                    setValue(`variacoes.${empresa.id}.${data.id}.cod_externo`, value);
                    setValue(`variacoes.${empresa.id}.${data.id}.is_dirty`, true);
                    setCodExternoValue(value);
                }}
                error={hasError}
                helperText={errors.variacoes?.[empresa.id]?.[data.id]?.cod_externo?.message}
            />
        </>
    );
};

function CompanyFields({ empresa, numEmpresas, variations, register, setValue, getValues, errors, clearErrors, replicateVariations }) {
    const [isChecked, setIsChecked] = useState(!!getValues(`id_empresas.${empresa.id}`));
    const [isOpened, setIsOpened] = useState(numEmpresas === 1);

    const variationsEmpresa = variations.filter((variation) => variation.contrato.id === empresa.idContrato);

    useEffect(() => {
        setIsChecked(!!getValues(`id_empresas.${empresa.id}`));
    }, [getValues, empresa.id]);

    return (
        <FormGroup>
            <Stack spacing={1} direction="row" alignItems="start" justifyContent="space-between" my={1} width={'100%'} flexWrap={'wrap'}>
                <Card
                    variant='elevation'
                    sx={{ width: '100%', paddingBottom: 0, backgroundColor: !isChecked && 'rgba(0, 0, 0, 0.04)' }}
                >
                    <CardContent
                        sx={{
                            paddingX: 1,
                            paddingY: isOpened ? 1 : 0,
                            paddingBottom: 0,
                            "&:last-child": {
                                paddingBottom: isOpened ? 3 : 0,
                            }
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                padding: 1,
                                borderRadius: 1,
                                cursor: isChecked ? 'pointer' : 'not-allowed'
                            }}
                            onClick={() => isChecked && setIsOpened(prev => !prev)}
                        >
                            <IconButton size='small' disabled={!isChecked}>
                                {isOpened ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>

                            <Typography sx={{ display: 'inline-block', ml: 1 }} color={isChecked ? 'text.primary' : 'text.disabled'}>
                                {empresa.nomeFantasia}
                            </Typography>
                        </Box>

                        <Collapse in={isOpened} timeout="auto" unmountOnExit>
                            <Divider sx={{ mb: 2 }} />

                            <Box>
                                {variationsEmpresa.length > 0 && (
                                    <>
                                        <Card variant="outlined" sx={{ flex: 1 }}>
                                            <CardContent>
                                                <Typography sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} mb={2}>
                                                    <Stack direction="row" alignItems="center">
                                                        <Category sx={{ mr: 1 }} color={'info'} />
                                                        Variações disponíveis para a empresa:
                                                    </Stack>
                                                </Typography>

                                                <Stack direction="column" alignItems="start" justifyContent="space-between" width={'100%'} flexWrap={'wrap'}>
                                                    <Grid container spacing={2}>
                                                        {variationsEmpresa.map((variacao) =>
                                                            <Grid item xs={12} sm={6} key={variacao.id}>
                                                                <VariationItem
                                                                    data={variacao}
                                                                    empresa={empresa}
                                                                    register={register}
                                                                    setValue={setValue}
                                                                    getValues={getValues}
                                                                    errors={errors}
                                                                    clearErrors={clearErrors}
                                                                    actionsAllowed={isChecked}
                                                                />
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </Stack>
                                            </CardContent>
                                        </Card>

                                        {/* {numEmpresas > 1 && (
                                            <Button
                                                sx={{ mt: 2 }}
                                                variant="text"
                                                size="small"
                                                onClick={() => replicateVariations(getValues(`variacoes.${empresa.id}`))}
                                                startIcon={<CheckCircleOutlineTwoTone />}
                                                disabled={!getValues(`variacoes.${empresa.id}`)}
                                            >
                                                Replicar valores para as demais empresas
                                            </Button>
                                        )} */}
                                    </>
                                )}
                            </Box>
                        </Collapse>
                    </CardContent>
                </Card>
            </Stack>
        </FormGroup >
    )
}

export function CompanyVariations({ empresasAssociadas, variations, register, setValue, getValues, errors, clearErrors }) {
    const replicateVariations = (vidaUtil) => {
        empresasAssociadas.forEach((empresa) => {
            const isChecked = getValues(`id_empresas.${empresa.id}`);
            if (isChecked) {
                setValue(`vida_util.${empresa.id}`, vidaUtil);
            }
        });
    };

    const numEmpresas = empresasAssociadas ? empresasAssociadas.length : 0;

    return (
        <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
            <FormControl sx={{ mx: 1 }} component="fieldset" variant="standard">
                <FormLabel component="legend" sx={{ mb: 1 }}>
                    Selecione as variações para o produto em cada empresa:
                </FormLabel>
                <>
                    {empresasAssociadas ? empresasAssociadas.map((empresa) => (
                        <FormControl key={empresa.id}>
                            <CompanyFields
                                empresa={empresa}
                                numEmpresas={numEmpresas}
                                variations={variations}
                                register={register}
                                setValue={setValue}
                                getValues={getValues}
                                errors={errors}
                                clearErrors={clearErrors}
                                replicateVariations={replicateVariations}
                            />
                        </FormControl>
                    ))
                        : <Typography variant='body2' sx={{ mt: 2 }}><CircularProgress size={20} /> Carregando empresas...</Typography>
                    }
                </>
                <FormHelperText>Selecione ao menos uma Variação</FormHelperText>
            </FormControl>
        </Box>
    );
}
