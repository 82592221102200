import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import {
    Breadcrumbs,
    Grid,
    Typography,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableHead,
    Paper,
    Stack,
    Button,
    Tooltip,
    IconButton,
} from '@mui/material';
import { Download, FileDownload, Home as HomeIcon } from '@mui/icons-material';
import PageTitle from '../../../common/PageTitle';
import LinkNavegacao from '../../../common/Link';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { checkPermissionsAndRedirect, hasPermission } from '../../../../configs/functions';
import { DatabaseExportIcon } from '../../../common/Icons';
import { EmptyTableRow } from '../../../common/EmptyTableRow';
import { listaExportacoes, listaTiposExportacao } from './configs/functions';
import { Pagination } from '../../../common/Pagination';
import { SkeletonTableRow } from '../../../common/SkeletonTableRow';
import { FiltersButton } from '../../../common/FiltersButton';
import { ExportsTableFilter } from './components/ExportsTableFilter';
import { ExportForm } from './components/ExportForm';
import { ExportStatusCell } from './components/ExportStatusCell';
import { formatDateTime } from '../../../../utils/format-date';
import { extractErrorMessage } from '../../../../utils/extract-error-message';

const ExportRow = ({ item }) => {
    const isSuccess = item.status === 'gerado' && item.url !== null;
    const formattedDate = formatDateTime(item.dataHora);

    return (
        <>
            <TableRow hover>
                <TableCell>{`${item.tipoExportacao.nome} - ${item.tipoExportacao.tipoArquivo}`}</TableCell>
                <TableCell>
                    <ExportStatusCell status={item.status} />
                </TableCell>
                <TableCell>{formattedDate}</TableCell>
                <TableCell>
                    {item.exibirItensExportados ? 'Sim' : 'Não'}
                </TableCell>
                <TableCell>
                    {item.exibirExportadosApi ? 'Sim' : 'Não'}
                </TableCell>
                <TableCell align='right'>
                    <Tooltip
                        placement="top"
                        title="Download do arquivo"
                    >
                        <IconButton
                            aria-label="download"
                            onClick={() => window.open(item.url, '_blank')}
                            disabled={!isSuccess}
                        >
                            <Download
                                color={isSuccess ? 'primary' : 'disabled'}
                                htmlColor='primary'
                            />
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
        </>
    );
};

export function ListOfExports() {
    const { exibirAlerta } = useCommonItems();

    const [searchParams, setSearchParams] = useSearchParams();

    const searchParamPage = searchParams.get('page');
    const searchParamPerPage = searchParams.get('perPage');

    const page = searchParamPage ? (parseInt(searchParamPage) - 1) : 0;
    const rowsPerPage = searchParamPerPage ? parseInt(searchParamPerPage) : 10;

    const [openExportForm, setOpenExportForm] = useState(false);

    // const [tiposExportacao, setTiposExportacao] = useState([]);
    // const [isLoadingCompanyData, setIsLoadingCompanyData] = useState(false);

    const [isOpenFilters, setIsOpenFilters] = useState(false);
    const [activeFilters, setActiveFilters] = useState(0);

    const queryFilters = {
        limit: rowsPerPage,
        offset: page * rowsPerPage,
        id_tipo_exportacao: searchParams.get('id_tipo_exportacao'),
        status: searchParams.get('status'),
        data_inicio: searchParams.get('data_inicio'),
        data_fim: searchParams.get('data_fim'),
    };

    const queryKey = ['listaExportacoes', queryFilters];
    const { data: queryModulosData, isLoading, isPending, error: listaExportacoesError } = useQuery({
        queryKey: queryKey,
        queryFn: () => listaExportacoes(queryFilters),
        refetchOnWindowFocus: false,
    });

    const exportacoes = queryModulosData?.data?.data || [];
    const numTotalItems = queryModulosData?.data?.numero_total ?? 0;
    const isLoadingExportacoes = isLoading || isPending;

    // const loadExportTypes = async () => {
    //     try {
    //         setIsLoadingCompanyData(true);
    //         const response = await listaTiposExportacao();
    //         setTiposExportacao(response.data.data);
    //     } catch (error) {
    //         exibirAlerta('Erro ao carregar os Tipos de Exportação', '', 'error');
    //     } finally {
    //         setIsLoadingCompanyData(false);
    //     }
    // }

    const {
        data: queryTiposExportacaoData,
        isLoading: isLoadingTiposExportacao,
        isPending: isPendingTiposExportacao,
        error: listaTiposExportacaoError
    } = useQuery({
        queryKey: ['listaTiposExportacao'],
        queryFn: () => listaTiposExportacao(),
        refetchOnWindowFocus: false,
    });

    const tiposExportacao = queryTiposExportacaoData?.data?.data || [];
    const isLoadingCompanyData = isLoadingTiposExportacao || isPendingTiposExportacao;

    useEffect(() => {
        if (openExportForm) return;

        const queryFilters = { ...Object.fromEntries(searchParams) }

        const tableFilters = { ...queryFilters };
        delete tableFilters.page;
        delete tableFilters.perPage;

        const activeFilters = Object.keys(tableFilters).length;

        setActiveFilters(activeFilters);
        setIsOpenFilters(activeFilters > 0);
    }, [openExportForm, searchParams]);

    useEffect(() => {
        const requiredPermissionsView = ['admin', 'admin_exportacao', 'list_exportacao'];
        checkPermissionsAndRedirect(requiredPermissionsView);

        setSearchParams((state) => {
            state.set('page', String(page + 1));
            state.set('perPage', String(rowsPerPage));
            return state;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let errorMessage = "";

        if (listaTiposExportacaoError) {
            errorMessage = extractErrorMessage(listaTiposExportacaoError, 'Erro ao carregar as configurações de exportação');
        } else if (listaExportacoesError) {
            errorMessage = extractErrorMessage(listaTiposExportacaoError, 'Erro ao carregar as exportações');
        }

        errorMessage && exibirAlerta('Ops! Ocorreu um erro', errorMessage, 'error');
    }, [exibirAlerta, listaExportacoesError, listaTiposExportacaoError]);

    return (
        <>
            <Helmet title="Exportações" defer={false} />

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="stretch"
                spacing={3}
            >
                <Grid item xs={12}>
                    <Breadcrumbs>
                        <LinkNavegacao to='/'><HomeIcon fontSize='small' /></LinkNavegacao>
                        <Typography variant='span'>Gerenciamento</Typography>
                        <LinkNavegacao to='/gerenciamento/configuracoes'>Configurações</LinkNavegacao>
                        <Typography variant='span'>Exportações</Typography>
                    </Breadcrumbs>

                    <PageTitle
                        icon={<DatabaseExportIcon fontSize='large' color='primary' />}
                        title='Exportações'
                        description='Visualize e gerencie suas exportações.'
                    />
                </Grid>

                <Grid item xs={12}>
                    <Stack direction={{ xs: 'column-reverse', md: 'row' }} spacing={2} justifyContent={'space-between'}>
                        <FiltersButton
                            isOpenFilters={isOpenFilters}
                            setIsOpenFilters={setIsOpenFilters}
                            activeFilters={activeFilters}
                        />

                        {hasPermission(["admin", "admin_exportacao", "create_exportacao"]) &&
                            <Button
                                variant="contained"
                                startIcon={<FileDownload />}
                                onClick={() => setOpenExportForm(true)}
                            >
                                Exportar Dados
                            </Button>
                        }
                    </Stack>
                </Grid>

                <ExportsTableFilter
                    isOpenFilters={isOpenFilters}
                    isLoadingList={isLoadingExportacoes}
                    tiposExportacao={tiposExportacao}
                    isLoadingCompanyData={isLoadingCompanyData}
                />

                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table aria-label="Exportações">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Exportação</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Data</TableCell>
                                    <TableCell>Exibir itens já exportados</TableCell>
                                    <TableCell>Exibir itens já exportados via API</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {isLoadingExportacoes ?
                                    <SkeletonTableRow
                                        numCells={6}
                                        numActions={1}
                                        numRows={rowsPerPage < 20 ? rowsPerPage : 5}
                                    />
                                    : exportacoes && exportacoes.length > 0 ?
                                        exportacoes?.map((item) => (
                                            <ExportRow
                                                key={item.id}
                                                item={item}
                                                handleExibirAlerta={exibirAlerta}
                                            />
                                        ))
                                        : <EmptyTableRow colSpan={6} />
                                }
                            </TableBody>
                        </Table>

                        <Pagination
                            numTotalItems={numTotalItems}
                            rowsPerPage={rowsPerPage}
                            page={page}
                        />
                    </TableContainer>
                </Grid>
            </Grid>

            <ExportForm
                open={openExportForm}
                setOpen={setOpenExportForm}
                queryKey={queryKey}
                tiposExportacao={tiposExportacao}
                isLoadingCompanyData={isLoadingCompanyData}
            />
        </>
    );
}